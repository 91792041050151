<template>
  <div ref="imageWrapper" class="images-wrapper">
    <!-- <span class="absolute top-4 left-4 before-after-tag project flex flex-row justify-center items-center">Progetto</span> -->
    <div>
      <span class="absolute right-4 before-after-tag actual flex flex-row justify-center items-center ">Attuale</span>
      <img class="before-image" ref="imageWrapperImage" :src="beforeImage" alt="Before image"/>
    </div>
    
    <div class="compare-overlay" :style="{ width: `${compareWidth}%` }">
      <div class="relative">
        <span class="absolute left-4 before-after-tag project flex flex-row justify-center items-center">Progetto</span>
        <img
          class="after-image"
          :src="afterImage"
          alt="After image"
          :style="{ width: `${width}px`, maxWidth: `${width}px` }"
        />
      </div>
      
    </div>
    <input
      class="compare__range"
      type="range"
      min="0"
      max="100"
      :step="step"
      v-model="compareWidth"
      tabindex="-1"
    />
    <div class="handle-wrap" :style="{ left: `${compareWidth}%` }">
      <div class="handle">
        <img :style="{
            'background-color': '#000000',
            'mask': 'url(\'' + require(`@/assets/img/icons/back.svg`) + '\')',
            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/back.svg`) + '\')',
            'mask-repeat': 'no-repeat'
        }"/>
        <img :style="{
            'background-color': '#000000',
            'mask': 'url(\'' + require(`@/assets/img/icons/continue.svg`) + '\')',
            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/continue.svg`) + '\')',
            'mask-repeat': 'no-repeat'
        }"/>
      </div>
      <span class="handle-line"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeforeAfter",
  props: {
    value: {
      type: Number,
      default: 50,
    },
    beforeImage: {
      type: String,
      default: "",
    },
    afterImage: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 0.1,
    },
  },
  data() {
    return {
      width: null,
      imageHeight: null,
      compareWidth: this.value,
      containerHeight: null,
    };
  },
  mounted() {
    this.setWidth(); // Call the function to set the width
    window.addEventListener("resize", this.resizeHandler);
  },
  updated() {
    this.setWidth(); // Update width on component updates
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  emits: ["setContainerHeight"],
  methods: {
    setWidth() {
      this.$nextTick(() => {
        if (this.$refs.imageWrapper) {
          this.width = this.$refs.imageWrapper.getBoundingClientRect().width;
        }

        // const image = this.$refs.imageWrapperImage;
        // if (image) {
        //   image.onload = () => {
        //     this.imageHeight = image.getBoundingClientRect().height;
        //     console.log("Image loaded, height:", this.imageHeight);
        //   };

        //   // If image is already loaded (cached), get the height immediately
        //   if (image.complete) {
        //     this.imageHeight = image.getBoundingClientRect().height;
        //   }
        // }
      });

      setTimeout(() => {
        if (this.$refs.imageWrapperImage) {
          this.imageHeight = this.$refs.imageWrapperImage.getBoundingClientRect().height;
          console.log("Image height:", this.imageHeight);
          if( this.containerHeight == null && this.imageHeight > 0 ) {
            this.setContainerHeight( this.imageHeight );
            this.containerHeight = this.imageHeight;
          }
        }
      }, 300);
    },
    resizeHandler() {
      this.containerHeight = null;
      this.setWidth();
    },
    setContainerHeight( value ) {
      this.$emit("setContainerHeight", value );
    }
  },
};
</script>

<style scoped>
.before-after-tag {
    background: #fff !important;
    height: 46px;
    z-index: 99999;
    font-size: 17px;
    border-radius: 30px;
    padding: 0px 15px;
    top: 1rem;
}
.before-after-tag.actual {
  z-index: 1;
}

.images-wrapper {
  width: 100%;
  position: relative;
  border-radius: 20px;
}
.compare-overlay {
  position: absolute;
  overflow: hidden;
  /* height: 95vh; */
  top: 0;

}
.before-image,
.after-image {
  width: 100%;
  /* height: 95vh; */
  border-radius: 20px;
  object-fit: contain;
}
.after-image {
  z-index: 2;
  width: 50%;
  position: relative;
}
.compare__range {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  width: 100%;
  left: 0;
  right: 0;
  height: 50px;
  cursor: ew-resize;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
}
.handle__arrow {
  position: absolute;
  width: 20px;
}
.handle__arrow--l {
  left: 0;
}
.handle__arrow--r {
  right: 0;
}
.handle-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #ffffff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
.handle-line {
  content: "";
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background: #ffffff;
  z-index: 4;
  pointer-events: none;
  user-select: none;
}

.handle img {
  width: 18px !important;
  height: 18px !important;
}

.handle img:first-child {
  margin-right: 10px;
}

@media screen and (orientation: portrait) {
  .images-wrapper {
    border-radius: 0px;
  }
  .compare-overlay {
    max-height: 50vh;
    border-radius: 0px;
  }
  .before-image,
  .after-image {
    max-height: 50vh;
    border-radius: 0px;
    object-fit:contain;
  }
  .before-after-tag {
    display: none;
  }
  .handle img {
    width: 10px !important;
    height: 10px !important;
  }
  .handle img:first-child {
    margin-right: 5px;
  }

  .handle {
    width: 35px;
    height: 35px;
  }
}

@media screen and (orientation: landscape) {
  .images-wrapper {
    border-radius: 0px;
  }
  .compare-overlay {
    /* height: 100vh; */
    border-radius: 0px;
  }
  .before-image,
  .after-image {
    max-height: 95vh;
    border-radius: 0px;
    object-fit: contain;
    
  }
  .before-after-tag {
    display: flex;
    /* bottom: 1rem !important; */
    height: 40px;
    font-size: 15px;
    /* top: 0px; */
  }

  .handle {
    width: 50px;
    height: 50px;
  }

  .handle img {
    width: 13px !important;
    height: 13px !important;
  }
  .handle img:first-child {
    margin-right: 5px;
  }

  

}



</style>