<template>
  <div id="top" class="lg:mt-0  nap-top-container nap-listing-page flex flex-col" @click="clickOutsideElement">
    <div ref="navBar">
      <navbar />
    </div>

    <main class="flex flex-col justify-start relative">

      <!-- <div v-if="!isMobile() && showFilter">
        <filter-popup @toggleFilter="toggleFilter" @setFilterParams="setFilterParams" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
      </div>

      <div v-if="isMobile() && showFilter">
        <filter-popup-mobile @toggleFilter="toggleFilter" @setFilterParams="setFilterParams" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
      </div>


      <div v-if="!isMobile() && !isSmallScreen()" class="na-filter-row block" ref="filterBar">
        <filter-row @setFilterParams="setFilterParams" @toggleMap="toggleMap" @toggleFilter="toggleFilter" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
      </div>

      <div v-if="isMobile() || isSmallScreen()" class="na-filter-row na-filter-row-mobile block" ref="filterBar">
        <filter-row-mobile @toggleView="toggleView" @toggleFilter="toggleFilter" :filterParams="filterParams" />
      </div> -->

      <div v-if="showFilter" class="lg:block md:hidden sm:hidden">
        <filter-popup @toggleFilter="toggleFilter" @setFilterParams="setFilterParams" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
      </div>

      <div v-if="showFilter" class="lg:hidden md:block sm:block">
        <filter-popup-mobile @toggleFilter="toggleFilter" @setFilterParams="setFilterParams" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
      </div>


      <div class="lg:block md:hidden sm:hidden na-filter-row large-screen" ref="filterBar">
        <filter-row @setFilterParams="setFilterParams" @toggleMap="toggleMap" @toggleFilter="toggleFilter" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
      </div>

      <div class="na-filter-row na-filter-row-mobile lg:hidden md:block sm:block sticky top-0" ref="filterBar">
        <filter-row-mobile @toggleView="toggleView" @toggleFilter="toggleFilter" :filterParams="filterParams" />
      </div>

      <div :class="['na-content-wrapper w-full relative' ]">
        <div id="listing-left-wrapper" :class="[
            'w-full listing-left-wrapper',
            showMap ? 'lg:w-1/2 lg:block md:block sm:hidden has-map': 'lg:w-full small-screen no-map'
          ]"
        >

          <div class="listing-left bg-white  z-10 relative">

            <!-- <div
              :class="[`na-listing-container lg:mt-0 lg:p-6 flex flex-col `, propertyCount == 0 ? 'items-center flex flex-row' : ``, isMobile() ? 'items-center' : '', isSmallScreen() ? 'grid grid-cols-1' : `lg:grid lg:grid-cols-${showMap ? '2' : '4'} gap-5` ] "
              :style="{ 'height': containerHeight }"> -->
            <div class="na-listing-container lg:p-6" :style="{ 'height': containerHeight }">
              <div
                :class="[` lg:mt-0 flex flex-col `, propertyCount == 0 ? 'items-start flex flex-row' : ``, isMobile() ? 'items-center' : '', `lg:grid md:grid lg:grid-cols-${gridCount} md:grid-cols-${gridCount} gap-5 place-items-center` ] "
                >

                <div :class="[`na-filter-row bg-transparent alt flex flex-row justify-center w-full lg:block md:hidden sm:hidden  col-span-${gridCount}`]" ref="filterBar" >
                  <city-zone-filter @setFilterParams="setFilterParams" @toggleMap="toggleMap" @toggleFilter="toggleFilter" :filterParams="filterParams" :cities="cities" :zones="zones" :typology="typology" />
                </div>

                <div v-if="loadingProperties == true" class="col-span-2 place-items-center flex flex-row items-center " ref="scroll-loader">
                  <h3 class="text-center" >Carico immobili...</h3>
                </div>

                <div v-if="propertyCount > 0 " v-for="( property, propertykey ) in properties" :key="propertykey" :class="[
                    'na-list-item flex flex-col ',
                    property['display'] == 1 ? '' : 'hidden'
                  ]">

                  <!-- <router-link :to="{ path: `/compra/${property['docId']}` }" clas="flex flex-row w-full"> -->


                  <div :class="[
                    'na-list-image-slider relative',
                  ]">
                    <!-- <div class="na-list-item-size absolute bottom-4 left-4 z-20 bg-white br-5 f13-ls05 text-black lh-13 px-3 py-1 font-bold">
                        <span class="text-666 f11-ls54">MQ</span> {{property.size}}
                      </div> -->



                    <vueper-slides :bullets="false" :touchable="false" :arrows="true" :fixed-height="true" :fade="true"
                      class="no-shadow mx-auto">

                      <template #arrow-left>
                          <img :style="{
                              'background-color': '#fff',
                              'mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-left.svg`) + '\')',
                              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-left.svg`) + '\')',
                              'mask-repeat': 'no-repeat'
                          }" class="" />
                      </template>

                      <template #arrow-right>
                          <img :style="{
                              'background-color': '#fff',
                              'mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-right.svg`) + '\')',
                              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-right.svg`) + '\')',
                              'mask-repeat': 'no-repeat'
                          }" class="" />
                      </template>
                      <!-- :fixed-height="false" -->

                      <vueper-slide v-for="(image, index) in property.picturePaths" :key="index">
                        <template #content>
                          <router-link :to="`/compra/${property['docId']}`" target="_blank"
                            class="nap-listing-slider-image w-full relative h-full loading" :style="{
                              'background-image': `url(${image})`
                            }">
                            <div class="image-container"></div>
                            <div class="image-container-2"></div>
                            <img
                              :src="image"
                              alt="Example Image"
                              class="hidden"
                              @load="removeLoadingClass"
                            />
                            &nbsp;
                            <!-- Public tag box -->
                            <div
                              v-if="property['publicStatusTags'] && Object.keys(property['publicStatusTags']).length > 0">

                              <div class="overlay-container justify-end w-full relative">
                                <template v-for="(value, key) in property['publicStatusTags']" :key="key">
                                  <div
                                    :class="['overlay-box-slider', { 'overlay-personalizzabile': key === 'Personalizzabile', 'overlay-novita': key === 'Novità!', 'overlay-venduto': key === 'Venduto', 'overlay-luxury': key === 'Luxury', 'overlay-in-trattativa': key === 'In trattativa' }]">
                                    <div>
                                      <h4 class="">{{ key }}</h4>
                                    </div>

                                  </div>

                                </template>
                              </div>

                            </div>
                            <!-- <img class="w-full h-full br-10" v-bind:src="image" alt=""> -->
                            <!-- <img class="object-cover br-10" v-bind:src="image" alt=""> -->
                          </router-link>
                        </template>
                      </vueper-slide>
                    </vueper-slides>
                  </div>

                  <!-- <div
                    class="na-list-prop-info cursor-pointer na-list-info lg:pt-4 lg:pr-4  p-4 flex flex-col justify-between"
                    @click="$router.push({
                      name: `SingleProperty`,
                      state: {
                        docId: property['docId'],
                        property: property
                      }
                    })"> -->
                  <router-link :to="`/compra/${property['docId']}`" target="_blank" class="na-list-prop-info cursor-pointer na-list-info flex flex-col justify-between" >
                    <div>

                      <ul class="list-item-feature status flex flex-row">
                        <li class="na-property-status-pill bg-primary flex flex-row items-center relative">

                          <div class="na-project-status-popup flex flex-col">
                            <div class="flex flex-row justify-between nheader items-center">
                              <div class="flex flex-row items-center">
                                  <img class="w-full" :style="{
                                    'width': '12px',
                                    'height': '12px',
                                    'background-color': '#000',
                                    'margin-right': '5px',
                                    'mask': 'url(\'' + require(`@/assets/img/icons/${property['projectStatus'].toLowerCase().replace(' ', '_') }.svg`) + '\')',
                                    '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/${property['projectStatus'].toLowerCase().replace(' ', '_') }.svg`) + '\')'
                                  }">
                                  <p>{{ property['projectStatus'] }}</p>
                              </div>
                              <div>
                                <a href="javascript:void(0)">Cosa significa?</a>
                              </div>

                            </div>
                            <div class="nbody">
                              <p>{{ projectStatueData[property['projectStatus']] }}</p> 
                            </div>
                          </div>
                          
                          <div class="">

                            <img class="w-full" :style="{
                              'width': '12px',
                              'height': '12px',
                              'background-color': '#ffffff',
                              'margin-right': '5px',
                              'mask': 'url(\'' + require(`@/assets/img/icons/${property['projectStatus'].toLowerCase().replace(' ', '_') }.svg`) + '\')',
                              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/${property['projectStatus'].toLowerCase().replace(' ', '_') }.svg`) + '\')',
                              'mask-repeat': 'no-repeat'
                            }">
                          </div>
                          <p>
                            {{ property['projectStatus'] }}
                          </p>
                          <div class="flex flex-row justify-center items-center na-project-status-info">
                            <img class="" :style="{
                              'width': '8px',
                              'height': '8px',
                              'background-color': '#489B79',
                              'mask': 'url(\'' + require(`@/assets/img/icons/info.svg`) + '\')',
                              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/info.svg`) + '\')',
                              'mask-repeat': 'no-repeat',
                              'mask-position': '1px'
                            }">
                          </div>

                        </li>
                      </ul>


                      <h3 class="flex flex-row justify-between f15-ls05"><span class="na-property-title-sett">{{ property.title }}</span></h3>
                      <h4 class="f12-ls011" >{{ (  property.zone != '' ? property.zone +', ' : '' )  }} {{ property.city }} </h4>

                      <ul class="list-item-feature features flex flex-row">
                        <li class="na-property-status-pill flex flex-row items-center"
                          v-for="(feature, icon) in property.features" :key="icon">
                          <div class="">

                            <img class="w-full" :style="{
                              'width': '12px',
                              'height': '12px',
                              'background-color': '#929292',
                              'margin-right': '5px',
                              'mask': 'url(\'' + require(`@/assets/img/icons/${icon}.svg`) + '\')',
                              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/${icon}.svg`) + '\')'
                            }">
                          </div>
                          <p>{{ feature }}</p>
                        </li>

                      </ul>
                    </div>
                    <div class="flex flex-row justify-between items-center">
                      <div class="na-list-item-price lg:text-right text-left">
                        {{ property.price }}
                      </div>
                      <div class="na-list-item-actions flex flex-row">
                        <div v-if=" property['project']['agency'] != null && property['project']['agency']['user'] != null && property['project']['agency']['user']['profilePicture'] != null" class="na-agency-logo-image">
                          <div :style="{
                            backgroundImage: `url(${property['project']['agency']['user']['profilePicture']})`,
                            
                          }"></div>
                        </div>
                        <div v-else class="na-agency-logo-image" style="border: none !important;"></div>

                      </div>
                    </div>

                  </router-link>
                  <!-- </router-link> -->

                </div>
                <div v-else :class="[`col-span-${gridCount}`]">
                  <div class="flex flex-row justify-center items-start h-24">
                    <!-- <h3 v-if="loadingProperties == true ">Caricamento...</h3> -->
                    <h3 class="text-center" v-if="loadingProperties == false">Nessun risultato per ora... ma la tua casa
                      dei
                      sogni è qui da qualche parte!</h3>
                    <!-- <h3 v-else>Caricamento...</h3> -->
                  </div>

                </div>

                

              </div>
            </div>

          </div>


        </div>

        <div v-if="showMap" :class="['na-listing-map-background lg:flex absolute right-0 top-0 z-0 lg:w-1/2 w-full h-full', showMap ? 'has-map' : '']" :style="{ 'width': mapContainerWidth }">
          <map-listing-page v-model:mapId="id2" v-bind:mapPosition="mapPosition"
            :propertyCount="mapPosition.property.length" />
        </div>
      </div>



      <mobile-popup-sorting :sortOptions="sortOptions" :toggleSort="toggleSort" :selectedSort="selectedSort"
        @toggleSorting="toggleSorting" @sortProperty="sortProperty" />

    </main>
    <footer-component />

    <div id="hidden-tmp-map" class="hidden"></div>
  </div>
</template>

<script>

import { collection, addDoc } from "firebase/firestore"
// the firestore instance
import {db, isProduction, firebaseApp, stagingConfig, productionConfig} from '../firebase/init.js';

import Navbar from "@/components/Navbars/ListingNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import Close from "vue-material-design-icons/Close.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
// import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapListingPage from "@/components/Maps/MapListingPage.vue";
// import { createPopper } from "@popperjs/core";
// import {Anitmate} from 'animate.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// import Slider from '@vueform/slider'
import MobilePopupSorting from "./Property/MobilePopupSorting.vue";
import FilterRow from "./Property/FilterRow.vue";
import FilterRowMobile from "./Property/FilterRowMobile.vue";
import FilterPopup from "./Property/FilterPopup.vue";
import FilterPopupMobile from "./Property/FilterPopupMobile.vue";
import CityZoneFilter from "./Property/CityZoneFilter.vue";
import NarTooltip from "../components/utils/Tooltip/NarTooltip.vue";


import {

  ref,
  getDownloadURL,
  getStorage
} from 'firebase/storage';


import { functions, storage } from '../firebase/init.js';
import { httpsCallable } from 'firebase/functions';
import firebase from 'firebase/compat/app';
import { italianProvinces } from '../app_const.js';


// https://www.vuescript.com/custom-range-slider/
export default {

  created() {
    this.getNewarcHomes();
    this.zones.sort();
    // console.log(this.zones);
  },
  mounted() {
    this.removeBodyOverflowHidden();
    this.updateHeaderHeight();
    window.addEventListener('resize', this.updateHeaderHeight);

    /*window.onscroll = () => {
      const { scrollHeight, scrollTop, clientHeight } = this.$refs['scroll-loader'];
      if (scrollHeight - scrollTop === clientHeight && this.loadingProperties == false ) {
        // Fetch the next page of data
         if( this.hasMoreProperties == true ) {
          this.getNextNewarcHomes();
         }
         
      }
    }; */

    
  },
  computed() {
    // this.containerHeight = `calc(100vh - (${this.navbarHeight} - ${this.filterBarHeight} )px)`;
    
  },
  unmounted() {
    this.removeBodyOverflowHidden();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeaderHeight);
  },
  updated() {
    this.calculateGirdCount();
  },
  
  methods: {

    

    setTooltipPosition(event) {
    
      const parent = event.target;

      const tooltip = parent.querySelector(".na-project-status-popup");

    
      if( tooltip == null ) return;

      // Check if the popup exists
      // if (popup) {
      //     // Set the 'top' CSS property dynamically based on the required calculation
      //     popup.style.top = "50px"; // You can replace '50px' with your logic to set the top value dynamically
      // }

      const mouseY = event.clientY;
      const mouseX = event.clientX;
      const windowHeight = window.innerHeight;

      // Calculate the available space above and below the button
      const spaceAbove = mouseY;
      const spaceBelow = windowHeight - mouseY;
      const tooltipHeight = 180;

      window.mytarget = event.target;

      // If there's enough space above to display the tooltip, position it above
      if (spaceBelow >= tooltipHeight) {
        tooltip.style.top = (mouseY ) +'px';
        // tooltip.style.left = (mouseX ) +'px';
      } else {
        tooltip.style.top = (mouseY - 210) +'px';
      }
    },

    removeLoadingClass(event) {
      // Remove the loading class once the image is loaded
      event.target.parentNode.classList.remove('loading');
    },

    toggleMap() {
      this.showMap = !this.showMap;
      // this.calculateGirdCount();
    },
    toggleView( view ) {
      if( view == 'list' ) {
        this.showMap = false;
      } else {
        this.showMap = true;
      }   

      // this.calculateGirdCount();
      
    },
    toggleFilter( status ) {
      this.showFilter = status;
    },

    updateHeaderHeight() {
      var adCardWidth = 325;
      if( document.getElementById('listing-left-wrapper') == null ) return;
      var contentWrapperWidth = document.getElementById('listing-left-wrapper').clientWidth;
      this.gridCount = Math.floor(contentWrapperWidth / adCardWidth);

      this.windowWidth = window.innerWidth;
      this.$nextTick(() => {
          if (this.$refs.filterBar && this.$refs.navBar) {
              this.filterBarHeight = this.$refs.filterBar.offsetHeight;
              this.navbarHeight = this.$refs.navBar.offsetHeight;
              let height = this.navbarHeight + this.filterBarHeight;
              if( this.isMobile() ) {
                this.containerHeight = `auto`;
              } else {
                this.containerHeight = `calc(100vh - ${height}px)`;
              }
              
              
              if( window.innerWidth >=  1130 ) {
                this.mapContainerWidth = `calc(100vw - 720px)`;
              } else if( window.innerWidth > 720 && window.innerWidth < 1130 ) {
                this.mapContainerWidth = `calc(100vw - 378px)`;
              } else {
                this.mapContainerWidth = `calc(100vw)`;
              }
          
          } else {
              console.error('Refs are not available');
          }
      });
    },
    calculateGirdCount() {
      var adCardWidth = 325;
      var contentWrapperWidth = document.getElementById('listing-left-wrapper').clientWidth;
      this.gridCount = Math.floor(contentWrapperWidth / adCardWidth);
      // console.log("GRID COUNT",this.gridCount, contentWrapperWidth, contentWrapperWidth / adCardWidth );
    },

    setFilterParams(filterParams) {
      this.filterParams = filterParams;
      this.filterProperties();
    },

    toggleSorting() {
      this.toggleSort = !this.toggleSort;
    },

    sortProperty(sortby) {

      this.selectedSort = sortby;
      this.loadingProperties = true;

      switch (sortby) {
        case 'priceHighToLow':
          this.properties = this.properties.sort((a, b) => b['orgPrice'] - a['orgPrice']);
          break;
        case 'priceLowToHigh':
          this.properties = this.properties.sort((a, b) => a['orgPrice'] - b['orgPrice']);
          break;
        case 'timeRecent':
          this.properties = this.properties.sort((a, b) => a['endDate'] - b['endDate']);
          break;
        case 'timeLater':
          this.properties = this.properties.sort((a, b) => b['endDate'] - a['endDate']);
          break;
        default:
          this.properties = this.properties.sort((a, b) => a['endDate'] - b['endDate']);
          this.selectedSort = "timeRecent";
          break;

      }

      this.loadingProperties = false;


    },

    // longPressStartTimer(docId) {
    //   // return;
    //   let timer = 0;
    //   if (this.isMobile()) {
    //     timer = 1500;
    //   }
    //   this.longPressTimer = setTimeout(() => {
    //     // handle long press action
    //     this.$router.push(`/compra/${docId}`);
    //   }, timer);
    // },
    longPressClearTimer() {
      clearTimeout(this.longPressTimer);
    },

    clickOutsideElement(e) {
      if (e.target.classList.contains('nap-top-container') || e.target.classList.contains('na-listing-filter-wrapper') || e.target.classList.contains('na-listing-container') || e.target.classList.length == 0) {
        this.showCityZoneFilter = false; // or whatever logic would close your dropdown 
      }
    },
    clearZones(city) {

      if (city == this.selectedCity) {
        this.selectedCity = "";
      }

      if (this.selectedCity.length == 0) {
        this.selectedZone = "";
      }
    },
    setZoneToEmpty(zone) {
      if (zone == this.selectedZone) this.selectedZone = "";
    },

    addBodyOverflowHidden() {
      document.body.classList.add('overflow-hidden');
    },
    removeBodyOverflowHidden() {
      document.body.classList.remove('overflow-hidden');
    },

    adjustTypo(item) {
      this.checkboxTypology = item;

      if (item == 'Tutte') {
        this.isTypoAllSelected = true;
      } else {
        this.isTypoAllSelected = false;
      }
      this.filterProperties();
    },

    filterProperties() {
      
      this.propertyCount = 0;
      this.loadingProperties = true;
      this.properties = this.properties.map((property) => {

        property['display'] = 1;
        
        if ( 
        this.filterParams.zone != null && this.filterParams.zone != '' && this.filterParams.zone != 'Tutte le zone'
        && this.filterParams.city != '' && this.filterParams.city != null 
        && this.filterParams.type != '' && this.filterParams.type != null  ) {

          if (property['province'] == this.filterParams.city && property['zone'] == this.filterParams.zone ) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }

          if (this.validateFilter(property)) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }
          // return property;

        } else if ( this.filterParams.zone != '' && this.filterParams.zone != null && this.filterParams.zone != 'Tutte le zone' ) {

          if (property['zone'] == this.filterParams.zone ) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }

          if (this.validateFilter(property)) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }
          // return property;

        } else if ( this.filterParams.city != null && this.filterParams.city != '' ) {

          if (property['province'] == this.filterParams.city ) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }

          if (this.validateFilter(property)) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }

          // return property;
        } else {

          if (this.validateFilter(property)) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }
        }

        if( this.filterParams.type != null && this.filterParams.type != '' && this.filterParams.type != this.typology[0] && property['display'] == 1) {
          if (property['projectStatus'] == this.filterParams.type ) {
            property['display'] = 1;
          } else {
            property['display'] = 0;
          }
        }

        return property;

      });

      this.propertyCount = 0;
      this.properties.map((property) => {

        if (property['display'] == 1) this.propertyCount++;

      });

      this.loadingProperties = false;

      // console.log(this.properties);
      this.mapPosition.property = [];
      this.properties.forEach((property) => {
        if (property['display'] == 1) {
          let positions = {
            lat: parseFloat(property['location']['lat']),
            lng: parseFloat(property['location']['lng']),
            // image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F7iURmZLZdUhPd8QqTJ5y%2F1678002223744-1%20(4).jpg?alt=media&token=9883cc88-3abc-4a87-9031-0dc4368a7eda',
            image: property['picturePaths'][0],
            title: property['title'],
            price: property['orgPrice'],
            area: parseInt(property['size'])
          };
          this.mapPosition.property.push(positions);
        }

      });

      // console.log(this.properties);
    },

    validateFilter(property) {

      if ( (parseInt(property['orgPrice']) >= this.filterParams.price[0] && parseInt(property['orgPrice']) <= this.filterParams.price[1])
        && (parseInt(property['size']) >= this.filterParams.area.areaMin && parseInt(property['size']) <= this.filterParams.area.areaMax )
        && property['display'] == 1
      ) {

        var flag = true;
        if( this.filterParams.bathrooms != 'Tutti' ) {
          if( parseInt(property['baths']) == this.filterParams.bathrooms ) {
            flag = true;
          } else {
            flag = false;
          }
        }

        if( this.filterParams.rooms != 'Tutti' ) {
          if( parseInt(property['locals']) == this.filterParams.rooms ) {
            flag = true;
          } else {
            flag = false;
          }
        }

        return flag;
      } else {
        return false;
      }
    },

    toggleCityZoneFilter() {
      this.showCityZoneFilter = !this.showCityZoneFilter;
      this.filterProperties();


    },

    makeCompleteActive(status) {
      this.isCompleteActive = status;
    },

    async fetchNewarcProjectDetails(propertyId) {
        const db = firebase.firestore();

        const projectRef = db.collection("newarcProjects").where('propertyId', "==", propertyId );
        let result = {};
        try {
            const doc = await projectRef.get();
            var project;
            
            if ( doc.docs.length > 0 && doc.docs[0].exists ) {
                project = doc.docs[0].data();
                project['id'] = doc.docs[0].id;

                if( project['assignedAgency']['agencyId'] != '' ) {
                  project['agency'] = await this.fetchAgencyDetails(project['assignedAgency']['agencyId']);
                }

                return project;

            } else {
              return null;
            }
        } catch(e) {
            
            return null;
        }
    
    },

    async fetchImmaginaProjectDetails(propertyId) {
        const db = firebase.firestore();

        const projectRef = db.collection("immaginaProjects").where('propertyId', "==", propertyId );
        let result = {};
        try {
            const doc = await projectRef.get();
            var project;
            
            if ( doc.docs.length > 0 && doc.docs[0].exists ) {
                project = doc.docs[0].data();
                project['id'] = doc.docs[0].id;

                if( project['agencyId'] != '' ) {                  
                  project['agency'] = await this.fetchAgencyDetails(project['agencyId']);
                }

                return project;

            } else {
              return null;
            }
        } catch(e) {
            return null;
        }
    
    },

    async fetchAgencyDetails( agencyId ) {

      const db = firebase.firestore();
      const newarcAgencyRef = db.collection("agencies").doc(agencyId);
      
      try {
          const doc = await newarcAgencyRef.get();
          if (doc.exists) {
              
              var agency = await doc.data();

              const userRef = db.collection("users").where('agencyId', '==', doc.id );
              const userdDoc = await userRef.get();
              
              if (userdDoc.docs.length > 0 ) {
              
                  agency['user'] = await userdDoc.docs[0].data();
                  if( agency['user']['profilePicture'] != null ) {
                  
                    let agencyImage = await this.getImageUrlFromFS(`agencies/${agency['user']['agencyId'].trim()}/${agency['user']['profilePicture'].trim()}`);
                    agency['user']['profilePicture'] = agencyImage;
                    return agency;
                  }
              }

              
          } else {
              //console.error(`No such document with ID ${this.docId}`);
              return null;
          } 
      } catch (error) {
          console.log('agency error', error);
          return null;
      }
      

    },

    async setProperties(result) {
      this.propertyCount = 0;
      this.loadingProperties = true;
      if (result.data.length > 0) {
        // console.log(result.length);
        for (let index = 0; index < result.data.length; index++) {

          var project; 
          
          
          if( result.data[index]['projectType'] == 'Newarc' ) {
            project = await this.fetchNewarcProjectDetails(result.data[index]['firebaseId']);
          } else {
            project = await this.fetchImmaginaProjectDetails(result.data[index]['firebaseId']);
          }

          if( project == null ) continue;
          
          let rooms = '';
          // switch (result.data[index]['baths']) {
          switch (parseInt(result.data[index]['locals'])) {
            case 1:
              rooms = 'Monolocale'
              break;
            case 2:
              rooms = 'Bilocale'
              break;
            case 3:
              rooms = 'Trilocale'
              break;
            case 4:
              rooms = 'Quadrilocale'
              break;
            default:
              rooms = 'Plurilocale'
              break;
          }

          let features = {
            'square-meters': result.data[index]['mq'] +' mq',
            'rooms': parseInt(result.data[index]['locals']).toString() + " locali",
            'bathrooms': result.data[index]['baths'] + ' bagni'
            // 'piano': result.data[index]['floors'] + ' piano',
            
          };

          let price = '€000';
          let originalPrice = 0;
          if (result.data[index] ['styles'].length > 0) {
            // Default price
            price = '€' + parseInt(result.data[index]['styles'][0]['price']).toLocaleString();
            originalPrice = parseInt(result.data[index]['styles'][0]['price']);

            for (let styleIndex = 0; styleIndex < result.data[index]['styles'].length; styleIndex++) {
              if (result.data[index]['styles']['isDefault'] == 1) {
                price = '€' + parseInt(result.data[index]['styles'][styleIndex]['price']).toLocaleString();
                originalPrice = parseInt(result.data[index]['styles'][styleIndex]['price']);
              }

            }

          }

          let propertyTitle = project['addressInfo']['streetName'] +' '+ project['addressInfo']['streetNumber'] +', '+project['addressInfo']['city'];

          let publicStatusTags = result.data[index]['publicStatus'];
          if (publicStatusTags != null) {
            Object.keys(publicStatusTags).forEach((key) => {
              if (publicStatusTags[key] === false) {
                delete publicStatusTags[key];
              }
            });
          }

          var projectStatus = "Progetto";
          

          if( result.data[index]['projectType'] == 'Newarc' ) {
            let startDate = 0;
            if( typeof project['jobStartDate'] != 'undefined'  ) {
              startDate = new Date(parseInt(project['jobStartDate'])).getTime();
            }
            
            let endDate = 0;
            if( typeof project['jobEndDate'] != 'undefined'  ) {
              endDate = new Date(parseInt(project['jobEndDate'])).getTime();
            }
            
            let hypotheticalJobEndDate =  0;
            if( typeof project['hypotheticalJobEndDate'] != 'undefined' ) {
              hypotheticalJobEndDate = project['hypotheticalJobEndDate'];
            }
            
            
            endDate = endDate > 0 ? endDate : new Date(parseInt(hypotheticalJobEndDate)).getTime();
            
            
            var currenttime = new Date().getTime();

            if( (project['type'] == 'Newarc Subito' || project['type'] == 'Newarc Insieme')  ) {
              if( endDate == 0 || (currenttime > startDate && currenttime < endDate)) {
                projectStatus = "In ristrutturazione";
              } else if( currenttime > endDate ) {
                projectStatus = "Completata";
              }
                
            } else {
              projectStatus = "Progetto";
            }
          }

          

          this.propertyCount++;
          let property = {
            'currency': '€',
            'display': 1,
            'docId': result.data[index]['firebaseId'],
            'insertTimestamp': result.data[index]['insertTimestamp'],
            'title': propertyTitle,
            'description': result.data[index]['description'],
            'location': {
              'lat': project['addressInfo']['latitude'],
              'lng': project['addressInfo']['longitude']
            },
            'size': result.data[index]['mq'],
            'price': price,
            'orgPrice': originalPrice,
            'eurpermq': 0,
            'features': features,
            'images': result.data[index]['picturePaths'] ?? [],
            'picturePaths': result.data[index]['photoDayTimePaths'],
            'phone': '',
            'share': '',
            'characteristics': result.data[index]['propertyFeatures'],
            'startDate': result.data[index]['startDate']??0,
            'endDate': result.data[index]['endDate']??0,
            'limitDate': result.data[index]['limitDate']??0,
            'styles': result.data[index]['styles'] ?? [],
            'city': result.data[index]['city'],
            'zone': result.data[index]['zone'],
            'locals': result.data[index]['locals'],
            'baths': result.data[index]['baths'],
            'type': result.data[index]['type'],
            'addressComponents': project['addressInfo']['latitude'],
            'publicStatusTags': publicStatusTags,
            'projectStatus': projectStatus,
            'project': project,
            'province': italianProvinces[project['addressInfo']['province']]
          };

          console.log(property);


          
          this.properties.push(property);
          // console.log('location',parseFloat(result.data[index]['location']['lat']), parseFloat(result.data[index]['location']['lng']));

        }

        
      }
      this.loadingProperties = false;

      
      this.loadStyleImageFromFb();


    },

    async loadStyleImageFromFb() {
      let locations = [];
      
      if (this.properties.length > 0) {

        for (let pIndex = 0; pIndex < this.properties.length; pIndex++) {
          let tmpProperty = this.properties[pIndex];
      
          if( tmpProperty['picturePaths'].length > 0 ) {

            for (let picIndex = 0; picIndex < tmpProperty['picturePaths'].length; picIndex++) {
              
              var fileurl = await this.getImageUrlFromFS(`${tmpProperty['picturePaths'][picIndex]['location']}${tmpProperty['picturePaths'][picIndex]['filename']}`);
              
              this.properties[pIndex]['picturePaths'][picIndex] = fileurl;
            
            }
          }
          

          if( tmpProperty['location'] != null ) {
            let positions = {
              lat: parseFloat(tmpProperty['location']['lat']),
              lng: parseFloat(tmpProperty['location']['lng']),
              // image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F7iURmZLZdUhPd8QqTJ5y%2F1678002223744-1%20(4).jpg?alt=media&token=9883cc88-3abc-4a87-9031-0dc4368a7eda',
              image: tmpProperty['picturePaths'][0],
              title: tmpProperty['title'],
              price: tmpProperty['orgPrice'],
              area: parseInt(tmpProperty['size'])
            };

            // this.mapPosition.property.push(positions);
            locations.push(positions);
          }
          

        }
      }
      this.mapPosition.property = locations;
      // console.log('Image loading done...');
    },

    navigateToDetails(property) {

    },

    async getNewarcHomes() {
      try {
        const db = firebase.firestore();
        const newarcHomesRef = db.collection("newarcHomes").where('isArchived', '==', false).where('isActive', '==', true)
          .orderBy("insertTimestamp", 'desc');
        const querySnapshot = await newarcHomesRef.get();
        const homes = [];
        querySnapshot.forEach((doc) => {
          homes.push(doc.data());
        });
        this.setProperties({ data: homes });
      } catch (error) {
        console.error(error);
        this.setProperties({ data: [] })
      }
    },
    async getNextNewarcHomes() {
      this.loadingProperties = true;
      const lastTimestamp = this.properties[this.properties.length - 1]['insertTimestamp'];


      try {
        const db = firebase.firestore();
        const newarcHomesRef = db.collection("newarcHomes").where('isActive', '==', true)
          .orderBy("insertTimestamp", 'desc')
          .startAfter(lastTimestamp)
          .limit(this.propertiesPerCall);
        const snapshot = await newarcHomesRef.get();
        const homes = snapshot.docs.map(doc => doc.data());
        // console.log(homes);
        if (homes.length > 0) {
          this.setProperties({ data: homes });
        } else {
          this.hasMoreProperties = false;
        }


      } catch (error) {
        console.error(error);
        this.setProperties({ data: [] });
      }
      this.loadingProperties = false;
    },
    
    async getImageUrlFromFS(filepath) {

      
      const _s = getStorage();

      const encodedPath = encodeURIComponent(filepath);

      var randomToken = Math.floor(1000 + Math.random() * 9000).toString() +
          '-' +
          Math.floor(1000 + Math.random() * 9000).toString() +
          '-' +
          Math.floor(1000 + Math.random() * 9000).toString() +
          '-' +
          Math.floor(1000 + Math.random() * 9000).toString();
      
      var fs_url = 'https://firebasestorage.googleapis.com/v0/b/' +
            _s.app.options.storageBucket +
            `/o/${encodedPath}?alt=media&token=` + randomToken;

      // print('fs_url',fs_url);

      return fs_url

      // try {
      //   this.properties[propertyKey]['picturePaths'][imageIndex] = fs_url;
      //   if (imageIndex == 0) {
      //     this.mapPosition.property[propertyKey]['image'] = fs_url;
      //   }
      // } catch (error) {
        
      // }
    

      // return fs_url;

      /*const storage = getStorage();
      const url = await getDownloadURL(ref(storage, `newarcHomes/${docId.trim()}/${image.trim()}`))
        .then((url) => {
          this.properties[propertyKey]['picturePaths'][imageIndex] = url;
          
          if (imageIndex == 0) {
            this.mapPosition.property[propertyKey]['image'] = url;
          }

          return url;

        })
        .catch((error) => {
          console.log( 'iamge erro', error);
          // Handle any errors
        });

      return url;*/

    },
    
    isMobile() {
      if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    isSmallScreen(){
      if( window.innerWidth < 1130 && window.innerWidth > 845 ) {
        return true;
      } else {
        return false;
      }
    },

    resetFilter() {
      this.price.value[0] = 1;
      this.price.value[1] = 1000000000;

      this.locals.value[0] = 1;
      this.locals.value[1] = 10;

      this.baths.value[0] = 1;
      this.baths.value[1] = 10;

      this.checkboxTypology = "Tutte";

    }
  },
  data() {
    return {
      radius: 400, // 400 meters
      apiKey: "YOUR_GOOGLE_MAPS_API_KEY", // Replace with your actual Google API key
      ame_types: ["school", "market", "park"],
      nearbyPlaces: { school: [], market: [], park: [] },
      loadingPlaces: true,
      gridCount: 2,
      windowWidth: 0,
      mapContainerWidth: 0,
      navbarHeight: 0,
      filterBarHeight: 0,
      showMap: this.isMobile() && window.innerWidth < 845 ? false : true,
      // showMap: true,
      showFilter: false,
      selectedSort: "",
      selectedSort: "timeRecent",
      toggleSort: false,
      sortOptions: {
        "priceHighToLow": "By Price high to low",
        "priceLowToHigh": "By Price low to high",
        "timeRecent": "By Time Finishing soon",
        "timeLater": "By Time Finishing later"
      },
      propertiesPerCall: 2,
      propertyCount: 0,
      hasMoreProperties: true,
      loadingProperties: true,
      isCompleteActive: true,
      showCityZoneFilter: false,
      selectedCity: "Torino",
      selectedZone: "Tutte le zone",
      cities: ['Torino'],
      zones: ["Campidoglio", "Colle della Maddalena", "San Secondo", "Pozzo Strada", "Crocetta", "San Salvario - Baretti", "Sassi", "Parella", "Lingotto", "Piazza Solferino", "Quadrilatero Romano", "Cavoretto", "Madonna del Pilone", "Superga", "Via della Rocca", "Santa Rita", "Via Roma", "Cit Turin", "San Donato", "Mirafiori Nord", "Gran Madre - Crimea", "San Salvario - Dante", "San Paolo", "Cenisia", "Nizza Millefonti", "Regio Parco", "Cittadella", "Città Giardino", "Vanchiglia", "Vanchiglietta", "Centro Europa", "Giardini Reali", "Italia 61"],
      // typology: ['Tutte', 'Appartamento', 'Villa', 'Casa indipendente', 'Loft', 'Attico', 'Mansarda'],
      typology: ['Tutte', 'Progetto', 'In ristrutturazione', 'Completata'],
      projectStatueData: {
        'Progetto': "Gli immobili di questo tipo sono case ancora da ristrutturare, per le quali esiste un progetto pronto ad essere realizzato. Su questi immobili puoi personalizzare la ristrutturazione e farla su misura per te. Contattaci e chiedi un preventivo di ristrutturazione gratuito! ", 
        'In ristrutturazione': "Gli immobili di questo tipo sono case in fase di ristrutturazione completamente personalizzabili nella scelta dei materiali, delle finiture e delle decorazioni.", 
        'Completata': "Gli immobili di questo tipo sono case già ristrutturate e subito pronte per essere abitate. Puoi ancora personalizzare le decorazioni, gli arredi e la cucina."
      },
      agency: {},
      filterParams: {
        city: '',
        zone: '',
        type: '',
        rooms: 'Tutti',
        bathrooms: 'Tutti',
        price: [1, 1000000000],
        area: {
          areaMin: 1,
          areaMax: 1000
        }
      },
      checkboxTypology: "Tutte",
      isTypoAllSelected: true,
      price: {
        value: [1, 1000000000],
        min: 1,
        max: 1000000000,
        step: 1000,
        tooltips: false,
        classes: ['nap-range-slider']

      },
      locals: {
        value: [1, 6],
        min: 1,
        max: 6,
        step: 1,
        tooltips: false,

      },
      baths: {
        value: [1, 4],
        min: 1,
        max: 4,
        step: 1,
        tooltips: false,

      },
      showFilterOnMobile: false,
      properties: [],
      filteredProperties: [],
      id2: "listing-map-canvas",
      mapMobile: "listing-map-canvas-mobile",
      mapPosition: {
        moveToRight: false,
        showPopup: true,
        center: {
          lat: 45.071379,
          lng: 7.679094
        },
        property: []
      }
    };
  },
  beforeMount() {
    // window.scrollTo(0, 0);
  },
  components: {
    Navbar,
    FooterComponent,
    MapListingPage,
    VueperSlides,
    VueperSlide,
    Close,
    ChevronDown,
    // Slider,
    MobilePopupSorting,
    FilterRow,
    FilterRowMobile,
    FilterPopup,
    FilterPopupMobile,
    CityZoneFilter,
    NarTooltip


  },
};


</script>
