<template>

  <div :class="[
            'nap-map-popup before-after-popup  nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0 justify-center items-center flex flex-row'
        ]">

    <div class="nap-contact-form-close-button absolute right-4 top-4 cursor-pointer z-50 flex flex-row items-center justify-center" @click="toggleBeforeAfterPopup()">
        <img :style="{
            'width': '16px',
            'height': '16px',
            'background-color': '#fff',
            'margin-right': '0px',
            'mask': 'url(\'' + require(`@/assets/img/icons/close-popup.svg`) + '\')',
            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/close-popup.svg`) + '\')',
            'mask-repeat': 'no-repeat'
            }">
    </div>

    <div class="before-after-title absolute flex flex-row justify-center">
      Confronta
    </div>

    <div class="relative before-after-popup-wrapper nap-popup-wrapper h-full z-40 flex flex-row items-center justify-center br-10">


      <div class="lg:w-11/12 h-full before-after-popup-container nap-popup-container nap-contact-form flex flex-row items-center justify-center bg-white br-10 relative" :style="{height: `${containerHeight}px !important`}">

        <div @click="previousSlide()" class="before-after-navigation previous">
          <img   :style="{
              'background-color': '#ffffff',
              'height': '27px',
              'width': '27px',
              'mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-left.svg`) + '\')',
              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-left.svg`) + '\')',
              'mask-repeat': 'no-repeat'
          }"/>
        </div>
        

        <ul v-if="beforeAfterImages.length > 0" class="before-after-popup-list" :style="{height: `${containerHeight}px`}">
          <li v-for="(image, index) in beforeAfterImages " :key="index" :class="['before-after-popup-list-item', displayIndex == index ? 'active' : '' ]" :style="{height: displayIndex == index ? `${containerHeight}px` : '0px' }">
            <before-after v-show="displayIndex == index "
                :beforeImage="image['before']"
                :afterImage="image['after']"
                :value="defaultSliderValue"
                @setContainerHeight="setContainerHeight"
            ></before-after>
          </li>
          
        </ul>

        <div @click="nextSlide()" class="before-after-navigation next">
          <img  class=""  :style="{
              'background-color': '#ffffff',
              'height': '27px',
              'width': '27px',
              
              'mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-right.svg`) + '\')',
              '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-right.svg`) + '\')',
              'mask-repeat': 'no-repeat'
          }"/>
        </div>
        
        
      </div>
    </div>
  </div>

</template>

<script>
import BeforeAfter from "./BeforeAfter.vue";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';

export default {
  name: "BeforeAfterPopup",
  props: {
    beforeAfterImages: Array
  },
  data() {
    return {
      defaultSliderValue: 50,
      compareWidth: this.value,
      displayIndex: 0,
      containerHeight: null,
    };
  },
  mounted() {
    // this.width = this.$refs.imageWrapper.getBoundingClientRect().width;
    // window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    // window.removeEventListener("resize", this.resizeHandler);
  },
  emits: ["toggleBeforeAfterPopup"],
  methods: {
    toggleBeforeAfterPopup(){
        this.$emit("toggleBeforeAfterPopup");
    },
    setContainerHeight(height) {
      this.containerHeight = height;
      console.log('containerheight', this.containerHeight);
    },
    nextSlide() {
      if( this.displayIndex == (this.beforeAfterImages.length - 1) ) {
        this.displayIndex = 0;
      } else {
        this.displayIndex++;
      }
    },
    previousSlide() {
      if( this.displayIndex == 0 ) {
        this.displayIndex = this.beforeAfterImages.length - 1;
      } else {
        this.displayIndex--;
      }
    },
  },
  components: {
    VueperSlides,
    VueperSlide,
    BeforeAfter
  }
};
</script>

<style scoped>
  .before-after-title {
    font-size: 20px;
    color: white;
    z-index: 44;
    top: 0px;
    padding: 10px;
    width: 100%;
    /* background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 100%); */
  }
  .before-after-navigation:hover {
    cursor: pointer;
    
  }
  .before-after-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999999;
    padding: 10px;
  }
  .before-after-navigation.previous {
    left: 0px;
  }
  .before-after-navigation.next {
    right: 0px;
  }

  .before-after-popup {
    background-color: rgba(0,0,0, 0.50);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
  }
  .before-after-popup-wrapper {
      /* height: 95vh; */
      margin-top: 2.5vh;
      border-radius: 20px;
      overflow: hidden;
  }

  .before-after-popup-container {
      /* height: 95vh; */
      border-radius: 20px;
      overflow: hidden;
  }

  .before-after-popup-list {
      /* height: 95vh; */
  }

  .before-image {
    /* height: 95vh; */
  }

  .before-after-popup-list-item {
    height: 0px;
  }
  .before-after-popup-list-item.active {
    /* height: 95vh; */
  }

  @media screen and (orientation: portrait) {
    .before-after-title {
      top: 1rem;
      padding: 0px;
    }
    .nap-contact-form-close-button {
        top: 0.5rem;
    }

    .before-after-popup-wrapper, .before-after-popup-container, .before-after-popup-list, .before-image, .before-after-popup-list-item.active {
      max-height: 50vh;
      border-radius: 0px;
    }
  }

  @media screen and (orientation: landscape) { 
    .before-after-popup-wrapper {
      margin-top: 0px;
    }
    .before-after-popup-wrapper, .before-after-popup-container, .before-after-popup-list, .before-image, .before-after-popup-list-item.active {
      height: auto;
      border-radius: 20px;
      /* width: 100vw; */
    }

    .before-after-title {
      background: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 23%) 100%);
    }
  }
</style>