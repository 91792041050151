<template>
    <div id="id" class="nap-listing-page">
        <div v-if="isLoading" class="fixed top-0 left-0 w-full property-loading flex flex-row justify-center items-center">
            <h1 class="text-white f25-ls06">Caricamento...</h1>
        </div>

        <div ref="navBar">
            <navbar />
        </div>
        <main class="flex flex-col justify-center relative">

            <!-- <div class="lg:hidden fixed w-full top-0 z-50 flex flex-row p-2 items-center nap-config-header-wrapper">
                <button @click="$router.go(-1)"
                    class="nap-config-slider-button br-10 bg-white mr-2 my-auto flex items-center justify-center">
                    &nbsp;
                    
                    <img src="@/assets/img/icons/back.svg" height="10" />
                </button>

                <div class="flex flex-row w-full justify-center">

                    <div>
                        <p class="mob-fixed-header-title">
                            {{ property.title }}
                        </p>
                    </div>
                </div>
            </div> -->
            <div :class="['relative w-full flex flex-row p-2 items-center justify-between nap-config-header-new-wrapper top-0 left-0 right-0 bg-white', showBottomSheetPricing ? '' : 'z-50' ]">
                
                <div @click="$router.push(`/compra/`)" :class="['na-listing-new-go-back cursor-pointer lg:absolute  lg:flex flex-row items-center justify-center py-2', showBottomSheetPricing ? '' : 'z-100']">
                    <span  class="justify-center lg:absolute left-2 lg:justify-start flex flex-row lg:w-auto w-full items-center lg:item-start">
                        <img class="w-full na-arrow-image" :style="{
                            'transform': 'rotate(180deg)',
                            'mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')',
                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')'
                        }">
                    </span>
                    <span class="lg:flex flex-row hidden">
                        <a href="javascript:void(0)">Tutti gli annunci</a>

                    </span>
                </div>

                <div class="flex flex-row lg:w-full w-auto lg:justify-center">

                    <div>
                        <p class="mob-fixed-header-title">
                            {{ property.title }}&nbsp;
                        </p>
                    </div>
                </div>
                
                
            </div>

            <!-- <div class="lg:hidden w-full flex flex-row items-center">
                
                <button v-if="parseInt(property.timeStatus) < 100" @click="showConfigurePopup()"
                    class="nap-popup-mobile-configure-button button-glow-green mt-20 p-3 mx-2 flex flex-row justify-center items-center lg:hidden block relative">
                    <span class="mr-2 absolute" style="margin-top:-4px; left:20px;">
                        <img src="@/assets/img/icons/white-pencil.png" height="13" />
                    </span>
                    <span>
                        Configura entro il {{ property.formattedEndDate }}
                    </span>

                </button>
                

            </div> -->



            <div id="slider-container" :class="[
                'na-listing-banner relative h-screen w-full lg:p-0 lg:mt-0 mt-1 mt-20 flex lg:flex-row flex-col', sliderActiveClass.replace(' ', '-')
            ]">

                <!-- autoplay -->
                <!-- <ul class="flex flex-row">
                    <li class="text-center flex flex-col justify-end items-center"
                        @click="slideThumbnailsMargin($refs, -1)">
                        <button>
                            <img style="transform: rotate(90deg);" width="25" src="@/assets/img/icon-down.png" alt="">
                        </button>
                    </li>
                </ul>
                <ul class="flex flex-row">
                    <li class="text-center flex flex-col justify-end items-center" @click="slideThumbnailsMargin($refs, 1)">
                        <button>
                            <img style="transform: rotate(270deg);" width="25" src="@/assets/img/icon-down.png" alt="">
                        </button>
                    </li>
                </ul> -->
                
                <vueper-slides  @click="showHideHeaderSliderMenu($refs.menuRef, 'hide'); showHideHeaderSliderMenu2($refs.menuRef2, 'hide')" v-if="sliderImages.length > 0 && sliderActiveClass != 'virtual tour' && sliderActiveClass != 'actual virtual tour'" ref="vueperslides1"
                    @slide="setSlideIndex($event.currentSlide.index)" :touchable="true" :arrows="true"
                    :bullets="isMobile == true ? false : true" :fixed-height="true" :fade="true"
                    class="relative no-shadow na-vueper-slide br-10">

                    <template #arrow-left>
                        <img :style="{
                            'background-color': '#fff',
                            'mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-left.svg`) + '\')',
                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-left.svg`) + '\')',
                            'mask-repeat': 'no-repeat'
                        }" class="" />
                    </template>

                    <template #arrow-right>
                        <img :style="{
                            'background-color': '#fff',
                            'mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-right.svg`) + '\')',
                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/slider-arrow-right.svg`) + '\')',
                            'mask-repeat': 'no-repeat'
                        }" class="" />
                    </template>
                    <!-- :fixed-height="false" -->

                    <vueper-slide class="main-slider" v-for="(image, index) in sliderImages " :key="index">
                        <template #content>

                            <div v-if="isImage(image)">
                                
                                <div v-if="nightModeImage(image, index)" :class="['na-has-day-night na-day-night-slider relative overflow-hidden nap-slide-image-container', activeImageMode ]">
                                    <div class="bnap-slide-image-container day-mode flex w-full relative overflow-hidden" 
                                    :class="{ 'active': activeImageMode === 'day' }"
                                    :style="{
                                            'background': `url('${image}')`,
                                        }">
                                            
                                    </div>
                                    <div v-if="nightModeImage(image, index) " 
                                        class="bnap-slide-image-container mode-night flex w-full relative overflow-hidden" :class="{ 'active': activeImageMode !== 'day' }"
                                        :style="{
                                                'background': `url('${nightModeImage(image, index)}')`,
                                            }">
                                                
                                    </div>
                                    
                                </div>
                                <div v-else class="nap-slide-image-container  flex w-full relative overflow-hidden" :style="{
                                        'background': `url('${image}')`
                                    }">
                                </div>
                            </div>
                            <div v-else class="nap-slide-image-container overflow-hidden grid grid-cols-1 justify-center items-center w-full">
                                <!-- <div id="video-placeholder" class="nap-slide-image-container flex-1  flex w-full relative overflow-hidden" :style="{
                                        'background': `url('${sliderImages[1]}')`
                                    }">
                                </div> -->
                                <!-- <video id="na-video-render" autoplay loop muted playsinline @oncanplay="hidePlaceholder()"> -->
                                <video id="na-video-render" style="width: 100%; height: 100%; object-fit: cover;" autoplay loop muted playsinline>
                                    <source :src="image" type="video/mp4">
                                    <source :src="image" type="video/webm">
                                    <source :src="image" type="video/ogg">
                                Your browser does not support the video tag.
                                </video>
                            </div>
                            
                            
                        </template>
                    </vueper-slide>
                </vueper-slides>

                <div v-show="sliderActiveClass == 'virtual tour'" class="flex flex-row w-full nap-slide-image-container nap-virtual-tour items-center" style="overflow: hidden">

                    <span @click="setSliderClassView('render');switchSliderImageGallery('render');" class="fixed top-3 right-3 lg:hidden flex">
                        <close fillColor="#fff" :size="25" style="margin-top: 4px; " />
                    </span>
                    <iframe width="100%" class="virtual-tour" height="90%" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" :src="virtualTourLink"></iframe>
                    
                </div>

                <div v-show="sliderActiveClass == 'actual virtual tour'" class="flex flex-row w-full nap-slide-image-container nap-virtual-tour items-center" style="overflow: hidden">

                    <span @click="setSliderClassView('actual render');switchSliderImageGallery('render');" class="fixed top-3 right-3 lg:hidden flex">
                        <close fillColor="#fff" :size="25" style="margin-top: 4px; " />
                    </span>
                    <iframe width="100%" class="virtual-tour" height="90%" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" :src="actualVirtualTourLink"></iframe>
                    
                </div>

                <!-- <header-sidebar :property="property" :configuration="configuration" :configurationOptions="configurationOptions" :progress="progress" :configurationOptionSelected="configurationOptionSelected" :showStyle="showStyle" @selectionConfigurationOption="selectionConfigurationOption" @hideConfigurePopup="hideConfigurePopup"  /> -->

                <div v-if="startAnimateDayNight" class="na-day-night-highlighter fixed top-0 left-0 h-full w-full"></div>

                <div v-show="hasNightMode && sliderActiveClass == 'render'" :class="['na-day-night-mode-bar lg:top-5 top-0 right-0 absolute z-20 px-4 flex flex-row justify-between items-end', startAnimateDayNight ? 'animating' : '']">
                    
                    <ul class="flex flex-row na-image-modes-container">
                        <li @click="setActiveDayNightMode('day');" :class="[ 'na-image-mode day', activeImageMode == 'day' ? 'active' : '' ]">
                            <img :style="{
                                'width': '18px',
                                'height': '18px',
                                'background-color': activeImageMode == 'day' ? '#000' : '#fff',
                                'mask': 'url(\'' + require(`@/assets/img/icons/day-mode.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/day-mode.svg`) + '\')'
                            }" class="" />
                        </li>
                        <li @click="setActiveDayNightMode('night');" :class="[ 'na-image-mode night', activeImageMode == 'night' ? 'active' : '' ]">
                            <img :style="{
                                'width': '12px',
                                'height': '18px',
                                'background-color': activeImageMode == 'night' ? '#000' : '#fff',
                                'mask': 'url(\'' + require(`@/assets/img/icons/night-mode.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/night-mode.svg`) + '\')'
                            }" class="" />
                        </li>
                        <div class="animated-box" :style="boxPosition"></div>
                    </ul>
                </div>

                <div class="na-banner-bottom-bar bottom-5 left-0 absolute z-20 px-4 flex flex-row justify-between w-full items-end">
                    
                    <div :class="['na-slider-class has-rows', project['type'] == 'Immagina' ? 'has-rows' : '']">
 
                        <nav class="flex flex-row lg:flex-col">
                            <!-- <div class="slider-nav-wrapper flex flex-row items-center"> -->
                            <div v-if="project['type'] == 'Immagina'" class="slider-nav-wrapper flex lg:flex-row flex-col items-center select-box">
                                <span class="slider-nav-title lg:flex hidden">Stato</span>
                                
                                <button class="select-trigger flex" @click="showHideHeaderSliderMenu2($refs.menuRef2, '');showHideHeaderSliderMenu($refs.menuRef, 'hide');" >
                                    <span>{{ stateSliderActiveClass == 'project' ? 'Progetto' : 'Attuale' }}</span>

                                    <div class="select-trigger-icon flex flex-col items-center justify-center">
                                        <img :style="{
                                            'width': '10px',
                                            'height': '10px',
                                            'background-color': '#ffffff',
                                            'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                                        }" class="" />
                                    </div>
                                </button>

                                <ul ref="menuRef2"  class="na-slider-class-menu na-slider-class-menu-state select-options" style="--transformJS: 0px; --widthJS: 123px;">
                                    
                                    <li @click="setSliderClassState('project');showHideHeaderSliderMenu2($refs.menuRef2, 'hide')" :class="[ 'project select-option', stateSliderActiveClass == 'project' || stateSliderActiveClass == '' ? 'active' : ''  ]">
                                        <a href="javascript:void(0)">Progetto</a>
                                    </li>
                                    <li  @click="setSliderClassState('actual');showHideHeaderSliderMenu2($refs.menuRef2, 'hide')" :class="[ 'actual select-option', stateSliderActiveClass == 'actual' ? 'active' : ''  ]">
                                        <a href="javascript:void(0)">Attuale</a>
                                    </li>

                                    

                                </ul>
                            </div>
                            
                            <div class="slider-nav-wrapper flex lg:flex-row flex-col items-center select-box">
                                <span class="slider-nav-title lg:flex hidden">Vista</span>
                                
                                <button @click="showHideHeaderSliderMenu($refs.menuRef, ''); showHideHeaderSliderMenu2($refs.menuRef2, 'hide');" class="select-trigger flex">
                                    <span v-if="sliderActiveClass == 'render'">Render</span>
                                    <span v-if="sliderActiveClass == 'virtual tour'">Virtual Tour</span>
                                    <span v-if="sliderActiveClass == 'planimetria'">Planimetria</span>
                                    <span v-if="sliderActiveClass == 'actual render'">Foto</span>
                                    <span v-if="sliderActiveClass == 'actual virtual tour'">Virtual Tour</span>
                                    <span v-if="sliderActiveClass == 'actual planimetria'">Planimetria</span>

                                    <div class="select-trigger-icon flex flex-col items-center justify-center">
                                        <img :style="{
                                            'width': '10px',
                                            'height': '10px',
                                            'background-color': '#ffffff',
                                            'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                                        }" class="" />
                                    </div>
                                </button>

                                <ul ref="menuRef" class="na-slider-class-menu na-slider-class-menu-view select-options" style="--transformJS: 0px; --widthJS: 123px;">
                                    <li v-if="stateSliderActiveClass == 'project' && renderImages.length > 0 " @click="setSliderClassView('render');switchSliderImageGallery('render');showHideHeaderSliderMenu($refs.menuRef, 'hide');" :class="[ 'render select-option', sliderActiveClass == 'render' ? 'active' : ''  ]">
                                        <a href="javascript:void(0)">Render</a>
                                    </li>
                                    <li  v-if="stateSliderActiveClass == 'project' && virtualTourLink != ''" @click="setSliderClassView('virtual tour');switchSliderImageGallery('virtual tour');showHideHeaderSliderMenu($refs.menuRef, 'hide');" :class="[ 'virtual-tour select-option', sliderActiveClass == 'virtual tour' ? 'active' : ''  ]">
                                        <a href="javascript:void(0)">Virtual Tour</a>
                                    </li>

                                    <li v-if="stateSliderActiveClass == 'project' && planImages.length > 0" @click="setSliderClassView('planimetria');switchSliderImageGallery('planimetria');showHideHeaderSliderMenu($refs.menuRef, 'hide');" :class="[ 'planimetria select-option', sliderActiveClass == 'planimetria' ? 'active' : ''  ]" >
                                        <a href="javascript:void(0)">Planimetria</a>
                                    </li>

                                    <li v-if="project['type'] == 'Immagina' && stateSliderActiveClass == 'actual' && actualRenderImages.length > 0 " @click="setSliderClassView('actual render');switchSliderImageGallery('actual render');showHideHeaderSliderMenu($refs.menuRef, 'hide');" :class="[ 'actual-render select-option', sliderActiveClass == 'actual render' ? 'active' : ''  ]">
                                        <a href="javascript:void(0)">Foto</a>
                                    </li>
                                    <li  v-if="project['type'] == 'Immagina' && stateSliderActiveClass == 'actual' && actualVirtualTourLink != ''" @click="setSliderClassView('actual virtual tour');switchSliderImageGallery('actual virtual tour');showHideHeaderSliderMenu($refs.menuRef, 'hide');" :class="[ 'actual-virtual-tour select-option', sliderActiveClass == 'actual virtual tour' ? 'active' : ''  ]">
                                        <a href="javascript:void(0)">Virtual Tour</a>
                                    </li>

                                    <li v-if="project['type'] == 'Immagina' && stateSliderActiveClass == 'actual' && actualPlanImages.length > 0" @click="setSliderClassView('actual planimetria');switchSliderImageGallery('actual planimetria');showHideHeaderSliderMenu($refs.menuRef, 'hide');" :class="[ 'actual-planimetria select-option', sliderActiveClass == 'actual planimetria' ? 'active' : ''  ]" >
                                        <a href="javascript:void(0)">Planimetria</a>
                                    </li>
                                    
                                </ul>

                                
                                    

                            </div>
                            
                        </nav>

                    </div>
                    
                    <!-- <div class="na-slider-thumbnails1 items-center lg:flex flex-row hidden justify-center" 
                    @mouseenter="$refs.vueperslides1['pauseAutoplay'](); autoPlaying = false"
                    @mouseleave="$refs.vueperslides1['resumeAutoplay'](); autoPlaying = false"> -->
                    <div v-show="sliderActiveClass != 'virtual tour' && sliderActiveClass != 'actual virtual tour'" class="na-slider-thumbnails1 items-center flex-row hidden justify-center">

                        <ul class="flex flex-row">
                            <li class="text-center flex flex-col justify-end items-center"
                                @click="slideThumbnailsMargin($refs, -1)">
                                <button>
                                    <img style="transform: rotate(90deg);" width="25" src="@/assets/img/icon-down.png" alt="">
                                </button>
                            </li>
                        </ul>
                        <div class="na-slide-thumbnail-container-1">
                            <ul class="flex flex-row" :style="{
                                marginLeft: slideMarginTop + 'px',
                                
                            }">
                                
                                <li v-bind:class="[
                                    'br-5',
                                    'cur' + slideCurrentIndex,
                                    slideCurrentIndex == i ? 'active' : ''
                                ]" v-for="(image, i) in sliderThumbnail" :key="i"
                                    v-on:click="$refs.vueperslides1.goToSlide(i)">
                                    <div class="nap-slider-thumbnail-image" :style="{
                                        'background': `url('${image}')`,

                                    }">
                                        &nbsp;

                                    </div>
                                    
                                </li>


                            </ul>
                        </div>

                        
                    </div>

                    <div v-show="beforeAfterImages.length > 0" class="items-center flex-row justify-center button-before-after-container">
                        <button type="button" class="button-before-after flex flex-row justify-center items-center" @click="toggleBeforeAfterPopup()">
                            <span>
                                <img class="w-full na-arrow-image" :style="{
                                    'background-color': '#000',
                                    'width': '17px',
                                    'height': '20px',
                                    'margin-right': '10px',
                                    'mask': 'url(\'' + require(`@/assets/img/icons/before-after.svg`) + '\')',
                                    '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/before-after.svg`) + '\')',
                                    'mask-repeat': 'no-repeat !important'
                                }">
                            </span>
                            Confronta
                        </button>
                    </div>

                    
                    <div v-if="showBottomSheetPricing" @click="closeBottomSheetPricing()" class="nap-mobile-bottom-sheet-overlay z-75"></div>
                    <!-- <div @click="toggleBottomSheetClass" :class="['na-property-calc flex flex-col fixed z-100 lg:relative items-center justify-center']"> -->
                    <div v-if="showBottomSheetPricing" class="nap-mobile-bottom-sheet nap-ps-container lg:hidden flex flex-col">

                        <h2 class="nap-sidebar-title">La tua nuova casa</h2>
                        <ul class="nap-sb-options">

                            <li v-if="configuration.length > 0" class="flex flex-col w-full nap-sb-main-li"
                                style="border-bottom:none;">

                                <div class="flex flex-row justify-between nap-sidebar-style-header">
                                    <p class="nap-sidebar-style-item-title">Valore immobile</p>
                                    <p class="nap-sidebar-style-item-price">{{ property.currency }}{{
                                        parseInt(configuration[configurationSelected]['price']).toLocaleString()
                                    }}</p>
                                </div>
                                
                            </li>

                        </ul>
                        <div class="nap-sb-options" v-if="configurationOptionSelected.length > 0">
                            <div class="flex flex-row justify-between nap-sidebar-style-header optional-header">
                                <p class="nap-sidebar-style-item-title">Optional</p>
                                <p class="nap-sidebar-style-item-price">{{ property.currency }}{{
                                    parseInt(optionalTotal).toLocaleString() }}</p>
                            </div>

                            <div class="flex flex-col">

                                <div v-for="options in configurationOptionSelected"
                                    class="flex flex-row justify-between nap-sidebar-optional-row">
                                    <span class="nap-bs-item-title">{{ options.title }}</span>
                                    <span class="nap-bs-item-price">{{ property.currency }}{{
                                        parseInt(options.price).toLocaleString() }}</span>
                                </div>

                            </div>

                        </div>
                        <ul v-if="project['type'] == 'Immagina'" class="nap-sb-options">
                        <!-- <ul class="nap-sb-options"> -->

                            <li class="flex flex-col w-full nap-sb-main-li" style="border-bottom:none;">

                                <div :class="['flex flex-row justify-between nap-sidebar-style-header ', includeRestoration ? '' : 'disabled' ]">
                                    <p class="nap-sidebar-style-item-title">
                                        <div class="flex flex-row items-center">
                                            <div class="flex items-center">
                                                
                                                <input type="checkbox" :checked="includeRestoration"  class="toggle" id="header-toggle" true-value="true"
                                                    @change="_toggleRestoration" false-value="false" />
                                                <label class="toggle-label" for="header-toggle"></label>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <label class="na-property-map-toggle-label">Ristrutturazione</label>
                                        </div>
                                        
                                    </p>
                                    <p class="nap-sidebar-style-item-price">{{ property.currency }}{{ parseInt(property['restorationCost']).toLocaleString() }}</p>
                                </div>

                                <div v-if=" includeRestoration && property['fullRenoIns'] != '' " :class="['flex flex-row justify-between nap-sidebar-style-header mt-3', includeRestoration ? '' : 'd-none' ]">
                                    <p class="na-restoration-item">Ristrutturazione Full</p>
                                    <p class="na-restoration-item-price">{{ parseInt(property['fullRenoIns']).toLocaleString() }}{{ property.currency }}</p>
                                </div>
                                <div v-if=" includeRestoration && property['materialStandardFull'] != '' " :class="['flex flex-row justify-between nap-sidebar-style-header', includeRestoration ? '' : 'd-none' ]">
                                    <p class="na-restoration-item">Materiali Standard</p>
                                    <p class="na-restoration-item-price">{{ parseInt(property['materialStandardFull']).toLocaleString() }}{{ property.currency }}</p>
                                </div>
                                <div v-if=" includeRestoration && property['materialFixtureFull'] != '' " :class="['flex flex-row justify-between nap-sidebar-style-header', includeRestoration ? '' : 'd-none' ]">
                                    <p class="na-restoration-item">Infissi</p>
                                    <p class="na-restoration-item-price">{{ parseInt(property['materialFixtureFull']).toLocaleString() }}{{ property.currency }}</p>
                                </div>

                            </li>

                        </ul>
                        
                        <div class="nap-sb-grand-total flex flex-row justify-between items-cener">
                            <p class="nap-gt-title">Totale</p>
                            <p class="nap-gt flex flex-col items-end">
                                <span class="nap-gt-p">
                                    {{ property.currency }} {{ parseInt(grandTotal).toLocaleString() }}
                                </span>
                                <span class="nap-gt-s">
                                    {{ parseInt(property['eurpermq']).toLocaleString() }} €/m²
                                </span>
                            </p>
                        </div>

                        <div>
                            <button class="flex flex-row justify-center w-full bg-green items-center na-property-calc-button" @click="showContactPopup()">
                                <div class="flex-1">Contatta l'agenzia</div>
                                <span>
                                    <img class="w-full na-arrow-image" :style="{
                                        'background-color': '#fff',
                                        'margin-right': '5px',
                                        'mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')',
                                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')'
                                    }">
                                </span>
                            </button>
                        </div>

                        <div class="bottomsheet-collapse-icon flex flex-row justify-center w-full items-center mb-3 mt-3" @click="closeBottomSheetPricing()">
                            <img :style="{
                                'width': '14px',
                                'height': '14px',
                                'background-color': '#000000',
                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                            }" class="" />
                        </div>
                    </div>
                    <div :class="['na-property-calc flex flex-col fixed z-100 lg:relative items-center justify-center']">

                        <div class="na-property-calc-details flex flex-col justify-start w-full">
                            <div class="na-property-calc-title">{{ property.title }}&nbsp;</div>
                            <!-- <div class="na-property-calc-sub-title">Optional : Nessuno</div> -->
                        </div>
                        
                        <div class="flex flex-row justify-between w-full items-center">
                            <div class="flex flex-row items-center" @click="openBottomSheetPricing">
                                <div v-if="project['type'] == 'Immagina' && isMobile" class="select-trigger-icon flex flex-col items-center justify-center mr-1">
                                    <img :style="{
                                        'width': '10px',
                                        'height': '10px',
                                        'background-color': '#ffffff',
                                        'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                                    }" class="" />
                                </div>
                                <div>
                                    <div class="na-property-calc-total">{{ parseInt(grandTotal).toLocaleString() }}{{ property.currency }}</div>
                                    <div class="na-property-calc-per-l"> {{ parseInt(property['eurpermq']).toLocaleString() }} €/mq </div>
                                </div>
                                
                                
                            </div>

                            <div>
                                <button class="flex flex-row bg-white items-center na-property-calc-button" @click="showContactPopup()">
                                    Contatta l'agenzia
                                    &nbsp;
                                    <span>
                                        <img class="w-full na-arrow-image" :style="{
                                            'background-color': '#000',
                                            'margin-right': '5px',
                                            'mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')',
                                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')'
                                        }">
                                    </span>
                                </button>
                            </div>
                        </div>
                        
                    </div>

                </div>
                






            </div>

            <div class="property-main-content w-full">

                <div class="container flex flex-row mx-auto">
                    <div class="main-content-left w-full lg:w-2/3 flex-initial p-2">

                        <!-- <before-after v-if="beforeAfterImages.length > 0"
                            :beforeImage="beforeAfterImages[0]['before']"
                            :afterImage="beforeAfterImages[0]['after']"
                            value="50"
                        /> -->

                        <div class="nap-section-1 mt-0">

                            <div class="nap-section-1-container">
                                <h5 v-if="typeof property['title'] != 'undefined' ">{{ property['title'] }}</h5>
                                <p v-if="typeof property['zone'] != 'undefined' "><strong>{{ (property['zone'] != '' ? property['zone'] + ', ' : '') + property['city'] }}</strong></p>

                                <ul class="the-property-features lg:flex lg:flex-wrap grid grid-cols-2 justify-start">

                                    <!-- <li v-for="(feature, findex) in property['features']" :class='`icon-${findex}`'> -->
                                    <li v-for="(feature, findex) in property['features']" >
                                        <span :class='`p-feature icon-${findex}`'></span>
                                        <span>
                                            {{ feature }}
                                        </span>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <!-- <div class="nap-section-2 flex lg:flex-row flex-col justify-center lg:justify-end">
                            <div>
                                <p>Una casa Newarc è unica.</p>
                                <a href="javascript:void(0)" @click="toggleFindHow()">Scopri perchè ></a>
                            </div>
                            <div class="w-full mx-auto lg:hidden block nap-scopri-mobile">
                                &nbsp;
                            </div>
                            
                        </div> -->

                        <div v-if="property['projectStatus'] != ''" class="nap-section-1 nap-jproject-type" :style="{
                            backgroundColor: `${projectStatusData[property['projectStatus']]['bg-color']}`,
                            borderColor: `${projectStatusData[property['projectStatus']]['border-color']}`
                        }">
                            
                            <div class="flex flex-row justify-between">
                                <h3 :style="{
                                        color: `${projectStatusData[property['projectStatus']]['heading-color']}`
                                    }">Tipologia</h3>

                                <div class="flex flex-row tag-project-status items-center" :style="{
                                            backgroundColor: `${projectStatusData[property['projectStatus']]['heading-color']}`
                                        }">
                                    <img class="w-full" :style="{
                                        'width': '15px',
                                        'height': '15px',
                                        'background-color': `${projectStatusData[property['projectStatus']]['tag-text-color']}`,
                                        'margin-right': '5px',
                                        'mask': 'url(\'' + require(`@/assets/img/icons/${property['projectStatus'].toLowerCase().replace(' ', '-') }.svg`) + '\')',
                                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/${property['projectStatus'].toLowerCase().replace(' ', '-') }.svg`) + '\')'
                                    }">
                                    <p :style="{
                                            color: `${projectStatusData[property['projectStatus']]['tag-text-color']}`
                                        }">{{ property['projectStatus'] }}</p>
                                </div>
                                
                            </div>
                            

                            <div class="nap-section-1-container flex flex-row">
                                <div class="p-status-image">
                                    <img class="w-full" :style="{
                                        'width': '48px',
                                        'height': '48px',
                                        'background-color': `${projectStatusData[property['projectStatus']]['heading-color']}`,
                                        'margin-right': '5px',
                                        'mask': 'url(\'' + require(`@/assets/img/icons/status-${property['projectStatus'].toLowerCase().replace(' ', '-') }.svg`) + '\')',
                                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/status-${property['projectStatus'].toLowerCase().replace(' ', '-') }.svg`) + '\')'
                                    }">
                                    
                                </div>
                                <div class="p-status-desciption">
                                    <p class="flex flex-col" :style="{
                                        color: `${projectStatusData[property['projectStatus']]['text-color']}`
                                    }">
                                        {{ projectStatusData[property['projectStatus']]['description'] }}

                                        <a @click="showContactPopup()" href="javascript:void(0)" class="mt-1" :style="{
                                            cursor: 'pointer',
                                            color: `${projectStatusData[property['projectStatus']]['link-color']}`
                                        }">Parlane con l’agenzia</a>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="nap-section-1">

                            <h3>Descrizione</h3>

                            <div class="nap-section-1-container">
                                <p class="pre-formatted">{{ property['description'] }}</p>
                            </div>
                        </div>

                        <div class="nap-section-1 tree">

                            <h3>Caratteristiche stato attuale</h3>

                            <div class="nap-section-1-container">
                                <ul class="the-property-chars grid grid-cols-2 justify-start">
                                    <li v-for="characterist in property['characteristics']"
                                        class="flex flex-row items-center">
                                        <img :style="{
                                            'background-color': '#646464',
                                            'mask': 'url(\'' + require(`@/assets/img/icons/character/${characterist.split(' ').join('-').toLowerCase().replace('.','')}.svg`) + '\')',
                                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/character/${characterist.split(' ').join('-').toLowerCase().replace('.','')}.svg`) + '\')'
                                        }"/>
                                        <!-- <span :class='`p-feature icon-superficie character`'></span> -->
                                        <span>
                                            {{ characterist }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            
                            <div v-if="project['type'] == 'Immagina' && typeof property != 'undefined' && typeof property['propertyCharacteristics'] != 'undefined' &&  property['propertyCharacteristics'].length > 0 " class="nap-section-1 inner green tree">
                            
                                <h3>Caratteristiche progetto <span :class='`p-feature green icon-shine`'></span></h3>
                                <ul class="the-property-chars green grid grid-cols-2 justify-start">
                                    <li v-for="characterist in property['propertyCharacteristics']"
                                        class="flex flex-row items-center">
                                        <img :style="{
                                            'background-color': '#499B79',
                                            'mask': 'url(\'' + require(`@/assets/img/icons/character/${characterist.split(' ').join('-').toLowerCase().replace('.','')}.svg`) + '\')',
                                            'mask-repeat': 'no-repeat',
                                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/character/${characterist.split(' ').join('-').toLowerCase().replace('.','')}.svg`) + '\')'
                                        }"/>
                                        <span>
                                            {{ characterist }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="nap-section-1">
                            <div class="flex flex-row justify-between">
                                <h3>Analisi della zona</h3>
                                <button @click="showMapPopup()" class="nap-header-link">
                                    Allarga mappa
                                </button>
                            </div>

                            <div class="nap-section-1-container">


                                <div v-if="isLoading == false" class="w-full" style="height:350px">
                                    <map-listing-page :mapId="id2" v-bind:mapPosition="this.mapPosition" :maxZoom="16" />
                                </div>

                                <div class="nap-eminities">
                                    <ul class="flex flex-row">
                                        <li class="flex flex-row items-center">
                                            <img src="@/assets/img/icons/markets.svg">
                                            <div class="flex flex-col items-center nap-eminities-counter">
                                                <h4 class="w-full">{{ amenitiesCount['market'] }}</h4>
                                                <p class="w-full lg:block hidden">Supermercati</p>
                                            </div>
                                        </li>
                                        <li class="flex flex-row items-center">
                                            <img src="@/assets/img/icons/schools.svg">
                                            <div class="flex flex-col items-center nap-eminities-counter">
                                                <h4 class="w-full">{{ amenitiesCount['school'] }}</h4>
                                                <p class="w-full lg:block hidden">Scuole</p>
                                            </div>
                                        </li>
                                        <li class="flex flex-row items-center">
                                            <img src="@/assets/img/icons/parks.svg">
                                            <div class="flex flex-col items-center nap-eminities-counter">
                                                <h4 class="w-full">{{ amenitiesCount['park'] }}</h4>
                                                <p class="w-full lg:block hidden">Parchi</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- COLLEGARE STA FRASE ALLA ZONA -->
                                <!--<p>San Paolo, zona residenziale ma ricca di servizi e attività di ogni genere. Il centro è
                                    facilmente raggiungibile grazie alla presenza di mezzi pubblici a pochi passi
                                    dall'appartamento.</p>-->

                            </div>
                        </div>

                        <div class="nap-section-1">

                            <h3>Esempio di mutuo</h3>

                            <div class="nap-section-1-container">
                                <div class="flex lg:flex-row flex-col lg:justify-evenly items-center flex-col-reverse">
                                    <div class="flex flex-col nap-mortgage-form w-full px-10 lg:mt-0 mt-4">
                                        <div class="flex flex-row nap-m-input items-center">
                                            <label class="w-1/3">Valore immobile</label>
                                            <input class="w-2/3 na-input br-6" v-model="price" type="number">
                                        </div>
                                        <div class="flex flex-row nap-m-input items-center">
                                            <label class="w-1/3">Importo richiesto</label>
                                            <input class="w-2/3 na-input br-6" v-model="ltv" type="number">
                                        </div>
                                        <div class="flex flex-row nap-m-input items-center">
                                            <label class="w-1/3">Durata mutuo</label>
                                            <select v-model="mort_duration" class="w-2/3 na-select br-6">
                                                <option value="25">25 Anni</option>
                                                <option value="20">20 Anni</option>
                                                <option value="15">15 Anni</option>
                                            </select>
                                        </div>
                                        <div class="flex">
                                            <button class="w-full bg-green text-white br-6 f15-ls043 font-bold p-3"
                                                @click="showContactPopup()">Chiedi un preventivo gratuito</button>
                                        </div>
                                    </div>

                                    <div class="nap-mortgage-details w-full flex flex-col px-10">
                                        <span class="nap-mortgage-rate">Tasso del mutuo <strong>3,5%</strong></span>

                                        <div class="circle-border mx-auto">
                                            <div class="circle flex flex-row items-center justify-center">
                                                <span class="nap-mortgage-value">
                                                    €<strong>{{ (((ltv * 2) * 0.035) / mort_duration).toFixed(0) }}</strong>

                                                </span>

                                            </div>
                                        </div>

                                        <div class="flex flex-row justify-between nap-mortgage-ratio mt-4">
                                            <span class="flex flex-row justify-start items-center">
                                                <span class="nap-bullet"></span>
                                                Anticipo (22,4%)
                                            </span>

                                            <span class="flex flex-row justify-start items-center">
                                                <span class="nap-bullet active"></span>
                                                Mutuo (77,6%)
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="nap-section-1">

                            <h3>L’agenzia immobiliare</h3>

                            <div class="nap-section-1-container flex lg:flex-row flex-col">
                                <div class="flex justify-center">
                                    <div v-if="agency['user'] != null && agency['user']['profilePicture'] != null" class="nap-jagency-logo">
                                        <div :style="{
                                            backgroundImage: `url(${agency['user']['profilePicture']})`,
                                            
                                        }"></div>
                                    </div>
                                    <div v-else class="nap-jagency-logo" style="border: none !important;"></div>
                                    
                                </div>
                                <div class="nap-jagency-content">

                                    <p class="nap-jagency-name">{{ agency['name'] }}</p>
                                    <p class="nap-jagency-address">{{ agency['address'] +", "+ agency['city'] }}</p>

                                    <div class="nap-jagency-phone flex flex-row">
                                        <img :style="{
                                        'background-color': '#489B79',
                                        'margin-right': '5px',
                                        'mask': 'url(\'' + require(`@/assets/img/icons/call-outline.svg`) + '\')',
                                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/call-outline.svg`) + '\')'
                                    }"/>
                                        <span>{{ agency['phone'] }}</span>
                                    </div>

                                    <button @click="showContactPopup()" class="nap-jagency-contact-button bg-green text-white" type="button">Contatta l’agenzia</button>
                                </div>


                            </div>
                        </div>

                    </div>

                    <!-- Sidebar -->
                    <div class="property-sidebar w-1/3 lg:block hidden">

                        <div id="sticky-anchor"></div>
                        <div id="sticky">
                            <div class="nap-property-sidebar">
                                <div class="nap-ps-container">
                                    <h2 class="nap-sidebar-title">La tua nuova casa</h2>
                                    <ul class="nap-sb-options">

                                        <li v-if="configuration.length > 0" class="flex flex-col w-full nap-sb-main-li"
                                            style="border-bottom:none;">

                                            <div class="flex flex-row justify-between nap-sidebar-style-header">
                                                <p class="nap-sidebar-style-item-title">Valore immobile</p>
                                                <p class="nap-sidebar-style-item-price">{{ property.currency }}{{
                                                    parseInt(configuration[configurationSelected]['price']).toLocaleString()
                                                }}</p>
                                            </div>
                                            
                                        </li>

                                    </ul>
                                    <div class="nap-sb-options" v-if="configurationOptionSelected.length > 0">
                                        <div class="flex flex-row justify-between nap-sidebar-style-header optional-header">
                                            <p class="nap-sidebar-style-item-title">Optional</p>
                                            <p class="nap-sidebar-style-item-price">{{ property.currency }}{{
                                                parseInt(optionalTotal).toLocaleString() }}</p>
                                        </div>

                                        <div class="flex flex-col">

                                            <div v-for="options in configurationOptionSelected"
                                                class="flex flex-row justify-between nap-sidebar-optional-row">
                                                <span class="nap-bs-item-title">{{ options.title }}</span>
                                                <span class="nap-bs-item-price">{{ property.currency }}{{
                                                    parseInt(options.price).toLocaleString() }}</span>
                                            </div>

                                        </div>

                                    </div>
                                    <ul v-if="project['type'] == 'Immagina'" class="nap-sb-options">
                                    <!-- <ul class="nap-sb-options"> -->

                                        <li class="flex flex-col w-full nap-sb-main-li" style="border-bottom:none;">

                                            <div :class="['flex flex-row justify-between nap-sidebar-style-header mb-3', includeRestoration ? '' : 'disabled' ]">
                                                <p class="nap-sidebar-style-item-title">
                                                    <div class="flex flex-row items-center">
                                                        <div class="flex items-center">
                                                            <input type="checkbox" :checked="includeRestoration" class="toggle" id="header-toggle" true-value="true"
                                                                @change="_toggleRestoration" false-value="false" />
                                                            <label class="toggle-label" for="header-toggle"></label>
                                                        </div>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <label class="na-property-map-toggle-label">Ristrutturazione</label>
                                                    </div>
                                                    
                                                </p>
                                                <p class="nap-sidebar-style-item-price">{{ property.currency }}{{ parseInt(property['restorationCost']).toLocaleString() }}</p>
                                            </div>

                                            <div v-if=" includeRestoration && property['fullRenoIns'] != '' " :class="['flex flex-row justify-between nap-sidebar-style-header', includeRestoration ? '' : 'd-none' ]">
                                                <p class="na-restoration-item">Ristrutturazione Full</p>
                                                <p class="na-restoration-item-price">{{ parseInt(property['fullRenoIns']).toLocaleString() }}{{ property.currency }}</p>
                                            </div>
                                            <div v-if=" includeRestoration && property['materialStandardFull'] != '' " :class="['flex flex-row justify-between nap-sidebar-style-header', includeRestoration ? '' : 'd-none' ]">
                                                <p class="na-restoration-item">Materiali Standard</p>
                                                <p class="na-restoration-item-price">{{ parseInt(property['materialStandardFull']).toLocaleString() }}{{ property.currency }}</p>
                                            </div>
                                            <div v-if=" includeRestoration && property['materialFixtureFull'] != '' " :class="['flex flex-row justify-between nap-sidebar-style-header', includeRestoration ? '' : 'd-none' ]">
                                                <p class="na-restoration-item">Infissi</p>
                                                <p class="na-restoration-item-price">{{ parseInt(property['materialFixtureFull']).toLocaleString() }}{{ property.currency }}</p>
                                            </div>

                                        </li>

                                    </ul>
                                    <!-- <ul v-if="project['type'] != 'Immagina' || (project['type'] == 'Immagina' && includeRestoration) " class="nap-sb-options">

                                        <li class="flex flex-col w-full nap-sb-main-li" style="border-bottom:none;">

                                            <div class="flex flex-row justify-between nap-sidebar-style-header">
                                                <p class="nap-sidebar-style-item-title">Materiali predefiniti</p>
                                                <p class="nap-sidebar-style-item-price">incluso</p>
                                            </div>
                                            


                                        </li>

                                    </ul> -->
                                    <div class="nap-sb-grand-total flex flex-row justify-between items-cener">
                                        <p class="nap-gt-title">Totale</p>
                                        <p class="nap-gt flex flex-col items-end">
                                            <span class="nap-gt-p">
                                                {{ property.currency }} {{ parseInt(grandTotal).toLocaleString() }}
                                            </span>
                                            <span class="nap-gt-s">
                                                {{ parseInt(property['eurpermq']).toLocaleString() }} €/m²
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div class="flex flex-row w-full justify-evenly items-center na-property-item-action-1 ">
                                    <div @click="scrollToTop()" class="nap-sb-config-button d-none flex flex-row text-center w-full justify-evenly items-center cursor-pointer" style="display: none;">
                                        <!-- <span>
                                            <brush-variant fillColor="#949494" :size="30" />
                                        </span> -->
                                        <span class="flex flex-col items-start">
                                            <span>Modifica</span>
                                        </span>

                                        <img src="@/assets/img/icons/modification-brush.png">

                                    </div>
                                    <button
                                        class="na-property-item-contanct bg-green text-white flex flex-row items-center w-full text-center justify-center relative"
                                        @click="showContactPopup()" style="margin:0px;">
                                        Contatta l'agenzia
                                        &nbsp;
                                        <span  class="justify-center lg:absolute right-6 lg:justify-start flex flex-row lg:w-auto w-full items-center lg:item-start">
                                            <img class="w-full na-arrow-image white" :style="{
                                                'mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')',
                                                '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')'
                                            }">
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <!-- <p class="nap-visit-history">Altre 5 persone hanno aperto l'annuncio oggi!</p> -->
                        </div>
                    </div>
                </div>

            </div>

        </main>
        <footer id="footer">

            <!-- <div class="container nap-footer">

                <div class="flex flex-col justify-center items-center">
                    <h2>Vorresti sapere di più su questo immobile?</h2>
                    <p>Ti richiamiamo noi!</p>
                    <button class="px-14 py-4" @click="showContactPopup">Contattaci</button>
                    <a style="cursor: pointer;" @click="$router.go(-1)">&lt; Torna agli annunci</a>
                </div>

            </div> -->

            <div class="container nap-footer">

                <div class="flex flex-col justify-center items-center">
                    <span  class="flex flex-row items-center  mb-10">
                        <img class="w-full na-arrow-image" :style="{
                            'transform': 'rotate(180deg)',
                            'mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')',
                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow.svg`) + '\')'
                        }">
                        &nbsp; <a class="" style="cursor: pointer;" @click="$router.go(-1)">Torna agli annunci</a>
                    </span>
                    
                </div>

            </div>

        </footer>

        <!-- Map popup -->

        <mobile-popup-map v-if="mapPosition.property.length > 0" v-bind:showMapPop="showMapPop"
            v-bind:mapPosition="mapPosition" @hideMapPopup="hideMapPopup" />

        <before-after-popup class="before-after-popup" v-show="beforeAfterImages.length > 0 && showBeforeAfterPopup" v-bind:beforeAfterImages="beforeAfterImages" @toggleBeforeAfterPopup="toggleBeforeAfterPopup" />

        <!-- contact popup desktop -->
        <popup-contact v-bind:property="property"  v-bind:project="project" v-bind:showContactPop="showContactPop" v-bind:agency="agency" @hideContactPopup="hideContactPopup" />

        <mobile-popup-configuration ref="napmobileconfiguratordiv" v-bind:progress="progress" v-bind:property="property"
            v-bind:showConfigPop="showConfigPop" v-bind:configuration="configuration"
            v-bind:configurationOptionSelected="configurationOptionSelected" v-bind:sliderImages="sliderImages"
            v-bind:configurationOptions="configurationOptions" v-bind:configurationSelected="configurationSelected"
            v-bind:price="price" v-bind:grandTotal="grandTotal" @toggleStyleMoreInfo="toggleStyleMoreInfo"
            @hideConfigurePopup="hideConfigurePopup" @updateSliderImages="updateSliderImages"
            @selectionConfigurationOption="selectionConfigurationOption" @setSlideIndex="setSlideIndex"
            @updateConfigurationSelection="updateConfigurationSelection" />

        <mobile-popup-layout v-bind:showLayoutPop="showLayoutPop" v-bind:buildingPlanImages="property['picturePaths']"
            @hideLayoutPopup="hideLayoutPopup" />


        <style-more-info v-bind:displayStyleMoreInfo="displayStyleMoreInfo" v-bind:selectedDesign="selectedDesign"
            v-bind:styleMoreInfo="styleMoreInfo" @toggleStyleMoreInfo="toggleStyleMoreInfo" />

        <popup-find-how v-bind:displayFindHow="displayFindHow" v-bind:findHowInfo="findHowInfo"
            @toggleFindHow="toggleFindHow" />

    </div>
</template>

<script>
import Navbar from "@/components/Navbars/ListingNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import ChevronLeft from "vue-material-design-icons/ChevronLeft.vue";
import Check from "vue-material-design-icons/Check.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import ExportVariant from "vue-material-design-icons/ExportVariant.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Close from "vue-material-design-icons/Close.vue";
import PencilOutline from "vue-material-design-icons/PencilOutline.vue";
import SendOutline from "vue-material-design-icons/SendOutline.vue";
import BrushVariant from "vue-material-design-icons/BrushVariant.vue";
import { createPopper } from "@popperjs/core";

import MobilePopupConfiguration from "./Property/MobilePopupConfiguration.vue";
import StyleMoreInfo from "./Property/StyleMoreInfo.vue";
import MobilePopupLayout from "./Property/MobilePopupLayout.vue";
import PopupContact from "./Property/PopupContact.vue";
import PopupFindHow from "./Property/PopupFindHow.vue";
import MobilePopupMap from "./Property/MobilePopupMap.vue";
// import BeforeAfter from "./Property/BeforeAfter.vue";
import BeforeAfterPopup from "./Property/BeforeAfterPopup.vue";
import HeaderSidebar from "./Property/Single/HeaderSidebar.vue";
import NarTooltip from "../components/utils/Tooltip/NarTooltip.vue";

// import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapListingPage from "@/components/Maps/MapListingPage.vue";
// import { createPopper } from "@popperjs/core";
// import {Anitmate} from 'animate.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';
import {

    ref,
    getDownloadURL,
    getStorage
} from 'firebase/storage';


import { functions, storage } from '../firebase/init.js';
import { httpsCallable } from 'firebase/functions';
import firebase from 'firebase/compat/app';


export default {

    props:
    {
        docId: { required: true }
        // property: { required: true}
    },
    mounted() {
        this.getNewarcHome();
        
        this.sidebarWidth = document.getElementById("sticky").offsetWidth;
        if (window.outerWidth > 600) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }

        this.addBodyOverflowHidden();
        // this.$refs.menuRef.style.display = "none"; 
        // this.$refs.menuRef2.style.display = "none"; 
        // document.addEventListener("click", this.handleClickOutside);

        // this.$refs.menuRef.style.display = "none"; 

    },
    unmounted() {
        this.removeBodyOverflowHidden();
    },
    created() {
        window.addEventListener('scroll', this.stickySidebar);
        
    },
    destroyed() {
        window.removeEventListener('scroll', this.stickySidebar);
    },
    beforeUnmount() {
        // document.removeEventListener("click", this.handleClickOutside);
    },
    computed: {
        boxPosition() {
            return {
                left: this.activeImageMode === 'day' ? '0px' : '40px', // Adjust as per li width
            };
        },
    },
    updated() {
        if( document.querySelector(".na-slider-class-menu-state") != null && document.querySelector(".na-slider-class-menu-state").offsetWidth == 370 ) {
            const menu = document.querySelector(".na-slider-class-menu-state");
            if( menu ) {
                const menuLinkActive = menu.querySelector("li.active");
                this.calculateStateLinkActive(menuLinkActive);    
            }

            const _menu = document.querySelector(".na-slider-class-menu-view");
            const _menuLinkActive = _menu.querySelector("li.active");
            this.calculateViewLinkActive(_menuLinkActive);    
        }
        
    },
    methods: {
        hidePlaceholder() {
            document.getElementById("video-placeholder").style.display = "none";
        },
        isImage( url ) {
            if (!url) return false;
    
            const extension = url.split(/[#?]/)[0].split('.').pop().toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];

            return imageExtensions.includes(extension);


        },
        toggleBeforeAfterPopup() {
            
            this.showBeforeAfterPopup = !this.showBeforeAfterPopup;
            
            if( this.showBeforeAfterPopup ) {
                
                this.addBodyOverflowHidden();
            } else {
                this.removeBodyOverflowHidden();
            }
        },
        closeBottomSheetPricing() {
            this.showBottomSheetPricing = false;
        },
        openBottomSheetPricing() {
            this.showBottomSheetPricing = true;
        },

        showHideHeaderSliderMenu( menuRef, hide ) {
            if( !this.isMobile ) return;
            if( menuRef ) {
                if( hide == 'hide' ) {
                    menuRef.style.display = "none";
                } else {
                    menuRef.style.display = menuRef.style.display === "none" || menuRef.style.display === "" ? "block" : "none";
                }
            }
            // this.showHideHeaderSliderMenu2(this.$refs.menuRef2, 'hide')
            
        },
        showHideHeaderSliderMenu2( menuRef2, hide ) {
            if( !this.isMobile ) return;
            if( menuRef2 ) {
                if( hide == 'hide' ) {
                    menuRef2.style.display = "none";
                } else {
                    menuRef2.style.display = menuRef2.style.display === "none" || menuRef2.style.display === "" ? "block" : "none";
                }
            }
            // this.showHideHeaderSliderMenu(this.$refs.menuRef, 'hide')
            
        },
        // handleClickOutside(event) {
        //     if ( this.$refs.menuRef && !this.$refs.menuRef.contains(event.target) ) {
        //         this.$refs.menuRef.style.display = "none";
        //     }
        // },
        loadGoogleMapsAPI() {
            
            return new Promise((resolve, reject) => {
                if (window.google && window.google.maps) {
                    resolve();
                } else {

                var apiKey = isProduction ? productionConfig['apiKey'] : stagingConfig['apiKey'];

                const script = document.createElement("script");
                script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
                }
            });
        },

        async fetchNearbyPlaces() {

            if (!window.google || !window.google.maps) {
                console.error("Google Maps API not loaded");
                return;
            }

            const service = new google.maps.places.PlacesService(document.createElement("div"));

            const fetchPlace = (type) => {
                return new Promise((resolve, reject) => {
                const request = {
                    location: new google.maps.LatLng(this.project['addressInfo']['latitude'], this.project['addressInfo']['longitude']),
                    radius: this.radius,
                    type: type
                };

                service.nearbySearch(request, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve({ type, results });
                    } else {
                    console.error(`Error fetching ${type}:`, status);
                    resolve({ type, results: [] });
                    }
                });
                });
            };

            const promises = this.ame_types.map(type => fetchPlace(type));

            const results = await Promise.all(promises);
            results.forEach(({ type, results }) => {
                this.nearbyPlaces[type] = results;
            });

            this.loadingPlaces = false;
        },
        setSliderNavFirstActive() {

            if( this.project['type'] != 'Immagina' ) return;

            const menu = document.querySelector(".na-slider-class-menu-state");
            if (menu) {
                const menuItems = menu.querySelectorAll("li");
                if (menuItems.length > 0) {
                    menuItems.forEach(item => item.classList.remove("active"));
                    menuItems[0].classList.add("active");
                    this.calculateStateLinkActive(menuItems[0]);
                }
            }
            // menu.style.setProperty("--transformJS", `0px`);

            const _menu = document.querySelector(".na-slider-class-menu-view");
            if (_menu) {
                const menuItems = _menu.querySelectorAll("li");
                if (menuItems.length > 0) {
                    menuItems.forEach(item => item.classList.remove("active"));
                    menuItems[0].classList.add("active");
                    this.calculateViewLinkActive(menuItems[0]);
                }

            }
            // _menu.style.setProperty("--transformJS", `0px`);
        },

        switchSliderImageGallery( type ){
            if( type == 'render' ) {
                this.sliderImages = this.renderImages;
                this.sliderThumbnail = this.renderImages;
            } else if( type == 'planimetria' ) {
                this.sliderImages = this.planImages;
                this.sliderThumbnail = this.planImages;
            } else if( type == 'virtual tour' ) {
                
            } else if( type == 'actual render' ) {
                this.sliderImages = this.actualRenderImages;
                this.sliderThumbnail = this.actualRenderImages;
            } else if( type == 'actual planimetria' ) {
                this.sliderImages = this.actualPlanImages;
                this.sliderThumbnail = this.actualPlanImages;
            } else if( type == 'actual virtual tour' ) {
                
            }

            
        },

        nightModeImage(image, index) {
            return this.renderNightImages[index];

        },
        
        setSliderClassState(_activeClass) {
            this.stateSliderActiveClass = _activeClass;
            var checkClass = _activeClass.replaceAll(' ', '-');
            
            setTimeout(() => {

                const menu = document.querySelector(".na-slider-class-menu-state");
                const menuItems = menu.querySelectorAll("li");
                if (menuItems.length > 0) {
                    menuItems.forEach(item => item.classList.remove("active"));
                }
                menu.querySelector(`li.${checkClass}`).classList.add("active")
                
                const menuLinkActive = menu.querySelector("li.active");
                this.calculateStateLinkActive(menuLinkActive);   

                // Set the first item as selected
                this.sliderActiveClass = _activeClass == 'project' ? 'render' : 'actual render';
                
                this.switchSliderImageGallery( this.sliderActiveClass );

                const _menu = document.querySelector(".na-slider-class-menu-view");
                if (_menu) {
                    const menuItems = _menu.querySelectorAll("li");
                    if (menuItems.length > 0) {
                        menuItems.forEach(item => item.classList.remove("active"));
                        menuItems[0].classList.add("active");
                        this.calculateViewLinkActive(menuItems[0]);
                    }

                }

            }, 100);

            
            
            
        },
        setSliderClassView(_activeClass) {

            this.sliderActiveClass = _activeClass;
            var checkClass = _activeClass.replaceAll(' ', '-');
            
            setTimeout(() => {
                const menu = document.querySelector(".na-slider-class-menu-view");

                const menuItems = menu.querySelectorAll("li");
                if (menuItems.length > 0) {
                    menuItems.forEach(item => item.classList.remove("active"));
                }
                menu.querySelector(`li.${checkClass}`).classList.add("active")

                const menuLinkActive = menu.querySelector("li.active");     
                this.calculateViewLinkActive(menuLinkActive);    

            }, 100);
            
            
        },
        toggleBottomSheetClass() {
            document.querySelector(".na-property-calc").classList.toggle('active'); // Toggles 'active' class on the clicked element
        },
        calculateViewLinkActive(link) {
            if( typeof link === "undefined" ) return;

            try {
                const _menu = document.querySelector(".na-slider-class-menu-view");
                _menu.style.setProperty("--transformJS", `${link.offsetLeft}px`);
                _menu.style.setProperty("--widthJS", `123px`);
            } catch (error) {
                const _menu = document.querySelector(".na-slider-class-menu-view");
                _menu.style.setProperty("--transformJS", `0px`);
                _menu.style.setProperty("--widthJS", `0px`);
            }

            
        },

        setActiveDayNightMode( mode ) {
            this.activeImageMode = mode;
            
        },
        calculateStateLinkActive(link) {
            if( typeof link === "undefined" ) return;

            try {
                const menu = document.querySelector(".na-slider-class-menu-state");
                menu.style.setProperty("--transformJS", `${link.offsetLeft}px`);
                menu.style.setProperty("--widthJS", `123px`);

            } catch (error) {
                const menu = document.querySelector(".na-slider-class-menu-state");
                menu.style.setProperty("--transformJS", `0px`);
                menu.style.setProperty("--widthJS", `0px`);

            }
        },

        
        
        handleMobilePopupScroll(e) {
            // console.log(e);
        },

        scrollToTop() {
            // window.scrollTo(0, 0);
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                    clearInterval(this.intervalId)
                }
                window.scroll(0, window.pageYOffset - 50)
            }, 20)
        },

        async fetchAgencyDetails( agencyId ) {

            const db = firebase.firestore();
            const newarcAgencyRef = db.collection("agencies").doc(agencyId);
            
            try {
                const doc = await newarcAgencyRef.get();
                
                if (doc.exists) {
                    
                    this.agency = await doc.data();
                    
                    const userRef = db.collection("users").where('agencyId', '==', doc.id );
                    const userdDoc = await userRef.get();
                    if (userdDoc.docs.length > 0 ) {
                        
                        this.agency['user'] = await userdDoc.docs[0].data();
                        if( this.agency['user']['profilePicture'] != null ) {
                            let agencyImage = this.photoUrls(`agencies/${this.agency['user']['agencyId'].trim()}/${this.agency['user']['profilePicture'].trim()}`);
                            this.agency['user']['profilePicture'] = agencyImage;
                            // this.agency['user']['profilePicture'] = await this.getAgencylogoImageUrl(this.agency['user']['agencyId'], this.agency['user']['profilePicture']);
                        }
                    }

                } else {
                    return;
                } 
            } catch (error) {
                // console.log('agency error', error);
            }
            

        },

        async fetchNewarcProjectDetails() {
            const db = firebase.firestore();

            const newarcHomesRef = db.collection("newarcProjects").where('propertyId', "==", this.docId );
            let result = {};
            try {
                const doc = await newarcHomesRef.get();
                
                if (doc.docs[0].exists ) {
                    this.project = doc.docs[0].data();
                    this.project['id'] = doc.docs[0].id;
                    
                    if( this.project['assignedAgency']['agencyId'] != '' ) {
                    
                        await this.fetchAgencyDetails(this.project['assignedAgency']['agencyId']);
                    }
                    

                }
            } catch(e) {
                // console.log('code fat', e);
            }
        
        },

        async fetchImmaginaProjectDetails() {
            const db = firebase.firestore();

            const newarcHomesRef = db.collection("immaginaProjects").where('propertyId', "==", this.docId );
            let result = {};
            try {
                const doc = await newarcHomesRef.get();
                
                if (doc.docs[0].exists ) {
                    this.project = doc.docs[0].data();
                    this.project['id'] = doc.docs[0].id;
                    
                    if( this.project['agencyId'] != '' ) {
                        await this.fetchAgencyDetails(this.project['agencyId']);
                    }

                    this.project['type'] = 'Immagina';
                    

                }
            } catch(e) {
                // console.log('code fat', e);
            }
        
        },

        async getNewarcHome() {

            
            const db = firebase.firestore();

            const newarcHomesRef = db.collection("newarcHomes").doc(this.docId);
            let result = {};
            try {
                const doc = await newarcHomesRef.get();
                if (doc.exists) {
                    
                    result = await doc.data();
                    if( result['projectType'] == 'Newarc' ) {
                        await this.fetchNewarcProjectDetails();
                    } else {
                        await this.fetchImmaginaProjectDetails();
                    }

                } else {
                    //console.error(`No such document with ID ${this.docId}`);
                    return;
                }
            } catch (error) {
                console.error('fetch home',error);
            }

            /* Get the Rooms to build the title of the property */
            let rooms = '';
            switch (parseInt(result['locals'])) {
                case 1:
                    rooms = 'Monolocale'
                    break;
                case 2:
                    rooms = 'Bilocale'
                    break;
                case 3:
                    rooms = 'Trilocale'
                    break;
                case 4:
                    rooms = 'Quadrilocale'
                    break;
                default:
                    rooms = 'Plurilocale'
                    break;
            }

            /* Set the features for the tile on the page */
            let features = {};

            if (result['mq'] != "") {
                features['superficie'] = result['mq'] + ' mq';
            }
            if (result['baths'] != "") {
                features['bagni'] = result['baths'] + ' bagni';
            }
            if (rooms != "") {
                features['locali'] = rooms;
            }
            if (result['floors'] != "") {
                features['piano'] = result['floors'].toLowerCase() != 'piano terra' && result['floors'].toLowerCase() != 'piano rialzato' ? result['floors'] +' piano' : result['floors'];
            }

            /*if( result['bedrooms'] != "" ) {
                features['locali'] = result['bedrooms'] + ' locali';
            }*/

            /* Property features. The icon of the feautre is stored in the assets.
            * The icon name is the lowercase and replace any space with - of the feature name
            */
            //let characteristics = result['propertyFeatures'];
            let characteristics = result['currentFeatures'];
            let propertyCharacteristics = [];
            if( this.project['type'] == 'Immagina' ) {
                propertyCharacteristics = result['propertyFeatures'];
            }


            /* Set initial price of the property to 0 */
            let price = '€000';
            let eurpermq = '€000';
            
            let picturePaths = [];

            /* Fetch Network url of the image from Firebase storage */
            if (result['picturePaths'] != null && result['picturePaths'].length > 0) {
                for (let imgIndex = 0; imgIndex < result['picturePaths'].length; imgIndex++) {
                    // let imgurl = this.getImageUrlFromFS(imgIndex, result['firebaseId'], result['picturePaths'][imgIndex]);

                    let imgurl = this.photoUrls(`newarcHomes/${result['firebaseId'].trim()}/${result['picturePaths'][imgIndex].trim()}`);
                    
                    // this.properties[propertyKey]['picturePaths'][imgIndex] = fs_url;
                    // if (imageIndex == 0) {
                    //     this.mapPosition.property[propertyKey]['image'] = fs_url;
                    // }

                    picturePaths.push(imgurl);
                }
            }
            

            /* Preset a Style for the user. It will be either the Default from the DB if default
            * not found then it will be one of the styles
            */
            let predefinedIndex = 0;
            this.configurationSelected = 0;

            /* Arrange the style and price of the styles */
            if (result['styles'].length > 0) {

                price = parseInt(result['styles'][0]['price']);
                eurpermq = price / parseInt(result['mq']);

                for (let sIndex = 0; sIndex < result['styles'].length; sIndex++) {

                    let description = result['styles'][sIndex]['description'];
                    this.configuration[sIndex] = {
                        'id': sIndex,
                        'is_predefined': result['styles'][sIndex]['isDefault'],
                        'title': result['styles'][sIndex]['styleName'],
                        'picturePaths': result['styles'][sIndex]['picturePaths'],
                        'details': description.split('-'),
                        'price': result['styles'][sIndex]['price'],
                        'images': []
                    };

                    /* Reassign the price of the property if found a default style from DB */
                    if (parseInt(result['styles'][sIndex]['isDefault']) == 1) {

                        predefinedIndex = sIndex;
                        price = parseInt(result['styles'][sIndex]['price']);

                        /* Calculate the price per SQM */
                        eurpermq = price / parseInt(result['mq']);

                        /* Set the index of the selected configurate to the Default style index */
                        this.configurationSelected = sIndex;
                    }


                    /* Fetch Network url of the style image from Firebase storage */
                    if (this.configuration[sIndex]['picturePaths'].length > 0) {
                        for (let imgIndex = 0; imgIndex < this.configuration[sIndex]['picturePaths'].length; imgIndex++) {
                            // this.getStyleImageUrlFromFS(sIndex, imgIndex, result['firebaseId'], this.configuration[sIndex]['picturePaths'][imgIndex]);

                            let fs_url = this.photoUrls(`newarcHomes/${result['firebaseId'].trim()}/${this.configuration[sIndex]['picturePaths'][imgIndex].trim()}`);

                            this.configuration[sIndex]['images'][imgIndex] = fs_url;

                        }
                    }

                }

                /* Set images of default style as the slider images and their thumnails */
                

            }

            let startDateFormatted = '';
            let limitDateFormatted = '';
            let endDateFormatted = '';
            let todayDateFormatted = '';
            let completed = 0;
            let endDate = 0;
            let startDate = 0;
            let hypotheticalJobEndDate = 0;

            if( result['type'] == 'Newarc' ) {
                /* Format the Start, End and Deadline dates for the Slider on the banner tooltip */
                let sDate = new Date(parseInt(result['startDate']));
                startDateFormatted = sDate.getDate().toString() + '/' + (sDate.getMonth() + 1).toString() + '/' + sDate.getFullYear().toString();

                let lDate = new Date(parseInt(result['limitDate']));
                limitDateFormatted = lDate.getDate().toString() + '/' + (lDate.getMonth() + 1).toString() + '/' + lDate.getFullYear().toString();

                let eDate = new Date(parseInt(result['endDate']));
                endDateFormatted = eDate.getDate().toString() + '/' + (eDate.getMonth() + 1).toString() + '/' + eDate.getFullYear().toString();

                let tDate = new Date();
                todayDateFormatted = tDate.getDate().toString() + '/' + (tDate.getMonth() + 1).toString() + '/' + tDate.getFullYear().toString();

                /* Calculate the Start, End and Deadline dates for the Slider on the banner Timeline */
                startDate = new Date(parseInt(this.project['jobStartDate'])).getTime();
                endDate = new Date(parseInt(this.project['jobEndDate'])).getTime();
                hypotheticalJobEndDate = typeof this.project['hypotheticalJobEndDate'] != 'undefined' ? this.project['hypotheticalJobEndDate'] : 0;
                endDate = endDate > 0 ? endDate : new Date(parseInt(hypotheticalJobEndDate)).getTime();

                // let startDate = new Date(parseInt(result['startDate'])).getTime();
                let today = new Date().getTime();
                let limitDate = new Date(parseInt(result['limitDate'])).getTime();
                // let endDate = new Date(parseInt(result['endDate'])).getTime();

                let startToTodayDifference = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
                let startToLimitDifference = Math.floor((limitDate - startDate) / (1000 * 60 * 60 * 24));
                let startToEndDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

                let deadline = (startToLimitDifference / startToEndDifference) * 100;
                this.progress['deadline'] = deadline > 100 ? '100' : deadline;

                completed = (startToTodayDifference / startToEndDifference) * 100;
                this.progress['completed'] = completed > 100 ? '100' : completed;

                /* Number of days to reach the deadline. */
                this.daysLeftToComplete = startToEndDifference;
            }

            

            /* Split the description with \n to display a line break on the page. */
            let descp = result['description']; //.split("\n");

            /* Generate the Property title based on the Rooms */
            let propertyTitle = '';
            if ( this.project['addressInfo'] != null) {
                
                propertyTitle = rooms + ' in ';
                
                if( this.project['addressInfo']['streetName'] != '' ) {
                    propertyTitle += this.project['addressInfo']['streetName'] +' ';
                }

                if( this.project['addressInfo']['streetNumber'] != '' ) {
                    propertyTitle += this.project['addressInfo']['streetNumber'];
                }

                if( this.project['addressInfo']['city'] != '' ) {
                    propertyTitle += ', '+this.project['addressInfo']['city'];
                }

                
            }

            var projectStatus = "Progetto";
            if( (this.project['type'] == 'Newarc Subito' || this.project['type'] == 'Newarc Insieme') ) {
                if( endDate == 0 || (today > startDate && today < endDate) ) {
                    projectStatus = "In ristrutturazione";
                } else if( today > endDate ) {
                    projectStatus = "Completata";
                }
            }
            
            /* Property variables */
            this.property = {
                'currency': '€',
                'docId': result['firebaseId'],
                'insertTimestamp': result['insertTimestamp'],
                'title': propertyTitle,
                'description': result['description'],
                'descp': descp,
                'location': {
                    'lat': this.project['addressInfo']['latitude'],
                    'lng': this.project['addressInfo']['longitude']
                },
                'zone': result['zone'],
                'city': result['city'],
                'size': result['mq'],
                'price': price,
                'eurpermq': eurpermq,
                'features': features,
                'images': result['picturePaths'] ?? [],
                'picturePaths': picturePaths,
                'phone': '',
                'share': '',
                'characteristics': characteristics,
                'propertyCharacteristics': propertyCharacteristics,
                'startDate': result['startDate'],
                'endDate': result['endDate'],
                'limitDate': result['limitDate'],
                'formattedStartDate': startDateFormatted,
                'formattedEndDate': endDateFormatted,
                'formattedLimitDate': limitDateFormatted,
                'formattedTodayDate': todayDateFormatted,
                'timeStatus': completed,
                'amenities': result['picturePaths'],
                'projectStatus': projectStatus,
                'fullRenoIns': result['FullRenoIns'],
                'materialStandardFull': result['materialStandardFull'],
                'materialFixtureFull': result['materialFixtureFull'],
                'restorationCost': 0,
                'iframeUrl': ''
            };

            this.property['iframeUrl'] = this.getIframeUrl();


            var restorationCost = 0;
            if( result['FullRenoIns'] != '' ) {
                restorationCost += parseFloat(result['FullRenoIns']);
            }

            if( result['materialFixtureFull'] != '' ) {
                restorationCost += parseFloat(result['materialFixtureFull']);
            }

            if( result['materialStandardFull'] != '' ) {
                restorationCost += parseFloat(result['materialStandardFull']);
            }
            this.property['restorationCost'] = restorationCost;

            
            // this.renderImages = this.configuration[predefinedIndex]['images'];
            let tmpRenderImages = [];
            let hasVideo = false;

            if( result['videoRenderPaths'] != null && result['videoRenderPaths'].length > 0 ) {
                let videoUrl = this.photoUrls(`newarcHomes/${result['firebaseId'].trim()}/video-render/${result['videoRenderPaths'][0].trim()}`);
                    
                this.renderImages.push(videoUrl);
                hasVideo = true; 
            }
            
            if( result['photoDayTimePaths'].length > 0 ) {

                for( let pd = 0; pd < result['photoDayTimePaths'].length; pd++ ) {
                    // console.log(result['photoDayTimePaths'][pd], this.project['id']);
                    let imgurl = this.photoUrls(`${result['photoDayTimePaths'][pd]['location']}${result['photoDayTimePaths'][pd]['filename']}`);
                    this.renderImages.push(imgurl);
                    tmpRenderImages.push(result['photoDayTimePaths'][pd]['filename']);
                }

                if( this.sliderActiveClass == '' ) {
                    this.sliderThumbnail = this.renderImages;
                    this.sliderImages = this.renderImages;
                    this.sliderActiveClass = 'render';
                }

            }

            if( result['photoNightTimePaths'].length > 0 ) {

                for( let pd = 0; pd < result['photoNightTimePaths'].length; pd++ ) {
                    // console.log(result['photoDayTimePaths'][pd], this.project['id']);
                    let _index = tmpRenderImages.indexOf( result['photoNightTimePaths'][pd]['day']['filename'] );
                    if( tmpRenderImages.indexOf( result['photoNightTimePaths'][pd]['day']['filename'] )> 0 ) {
                        let imgurl = this.photoUrls(`${result['photoNightTimePaths'][pd]['night']['location']}${result['photoNightTimePaths'][pd]['night']['filename']}`);

                        // this.renderNightImages.push(imgurl);
                        let _i = hasVideo ? _index + 1 : _index;
                        this.renderNightImages[_i] = imgurl;
                    } 
                    // else {
                    //     this.renderNightImages.push('');
                        
                    // }
                
                }

            }

            if( result['photoBeforeAfterPaths'].length > 0 ) {

                for( let pd = 0; pd < result['photoBeforeAfterPaths'].length; pd++ ) {
                    // console.log(result['photoDayTimePaths'][pd], this.project['id']);
                    if( result['photoBeforeAfterPaths'][pd]['before']['filename'] != '' ) {
                        
                        let afterImgUrl = this.photoUrls(`${result['photoBeforeAfterPaths'][pd]['before']['location']}${result['photoBeforeAfterPaths'][pd]['before']['filename']}`);

                        let beforeImgUrl = this.photoUrls(`${result['photoBeforeAfterPaths'][pd]['after']['location']}${result['photoBeforeAfterPaths'][pd]['after']['filename']}`);

                        // this.renderNightImages.push(imgurl);
                        this.beforeAfterImages.push({ 'before': beforeImgUrl, 'after': afterImgUrl });
                    } 
                    // else {
                    //     this.renderNightImages.push('');
                        
                    // }
                
                }

            }

            if( result['virtualTour'] != null && result['virtualTour'] != '' && this.isValidHttpUrl(result['virtualTour'] ) != false ) {
                this.virtualTourLink = result['virtualTour'];
            }

            this.planImages = this.property['images'];

            if( this.planImages.length > 0 ) {
                
                const _s = getStorage();

                for (let index = 0; index < this.planImages.length; index++) {
                    const element = this.planImages[index];
                    var fs_url = this.photoUrls(`newarcHomes/${result['firebaseId']}/${element.trim()}`);
                    this.planImages[index] = fs_url;
                    
                }

            }

            if( typeof result['currentVirtualTour'] != 'undefined' && result['currentVirtualTour'] != null && result['currentVirtualTour'] != '' && this.isValidHttpUrl(result['currentVirtualTour'] ) != false ) {
                this.actualVirtualTourLink = result['currentVirtualTour'];
            }

            if( typeof result['photoBeforeAfterPaths'] != 'undefined' && result['photoBeforeAfterPaths'].length > 0 ) {

                for( let pd = 0; pd < result['photoBeforeAfterPaths'].length; pd++ ) {
                    // console.log(result['photoDayTimePaths'][pd], this.project['id']);
                    let imgurl = this.photoUrls(`newarcHomes/${this.property['docId']}/before-after-render/${result['photoBeforeAfterPaths'][pd]['after']['filename']}`);
                    this.actualRenderImages.push(imgurl);
                }

            }

            if( typeof result['currenPlan'] != 'undefined' && result['currenPlan'].length > 0 ) {
                
                this.actualPlanImages = result['currenPlan'];
                for (let index = 0; index < this.actualPlanImages.length; index++) {
                    const element = this.actualPlanImages[index];
                    var fs_url = this.photoUrls(`newarcHomes/${this.property['docId']}/current-plan/${element.trim()}`);
                    this.actualPlanImages[index] = fs_url;
                    
                }

            }

            /* Setting Optional */
            if (typeof result['optional'] !== 'undefined') {
                this.configurationOptions = result['optional'];
            } else {
                this.configurationOptions = [];
            }

            /* Store the location of the property to display on the map */
            if (typeof this.property['location'] != 'undefined') {
                this.mapPosition['property'].push(this.property['location']);
            }

            /* Update the price on the calculator */
            this.updatePrice();

            await this.fetchNearbyPlaces();

            let amenities_limit = 10;

            Object.keys(this.nearbyPlaces).forEach(ame_name => {
                this.mapPosition[ame_name] = [];

                var amenities_chunk = this.nearbyPlaces[ame_name].splice(0, amenities_limit);
                this.amenitiesCount[ame_name] = amenities_chunk.length + "" + (this.nearbyPlaces[ame_name].length > amenities_limit ? '+' : '');

                Object.keys(amenities_chunk).forEach(ame_item => {
                    let loc = amenities_chunk[ame_item];
                    this.mapPosition[ame_name].push({
                        'lat': loc.geometry.location.lat(),
                        'lng': loc.geometry.location.lng(),
                    });

                });
            });

            this.isLoading = false;

            /* Add scrolling to the body after the property is read to display */
            this.removeBodyOverflowHidden();

        },

        toggleBottomSheet() {
            // console.log('BS');
            this.isBottomExpanded = !this.isBottomExpanded;

            if( this.isBottomExpanded == true ) {
                this.addBodyOverflowHidden();
            } else {
                this.removeBodyOverflowHidden();
            }
        },

        toggleStyleMoreInfo(selectedStyleMoreInfo) {
            this.selectedDesign = selectedStyleMoreInfo;
            this.displayStyleMoreInfo = !this.displayStyleMoreInfo;
        },

        getIframeUrl(){
            // console.log(this.agency);
            return `https://www.newarc.it/caseristrutturate-form-agenzia/?adId=${ this.property['docId'] }&projectId=${ this.project['id'] }&agencyId=${ this.agency['user']['agencyId'] }&agencyEmail=${ this.agency['email'] }&legalName=${ this.agency['name'] }`;
        },

        generateRandomToken(){
            var randomToken = Math.floor(1000 + Math.random() * 9000).toString() +
                '-' +
                Math.floor(1000 + Math.random() * 9000).toString() +
                '-' +
                Math.floor(1000 + Math.random() * 9000).toString() +
                '-' +
                Math.floor(1000 + Math.random() * 9000).toString();

            return randomToken;
        },

        photoUrls( filepath ) {
            const _s = getStorage();

            const encodedPath = encodeURIComponent(filepath);

            var randomToken = this.generateRandomToken();
            
            var fs_url = 'https://firebasestorage.googleapis.com/v0/b/' +
                    _s.app.options.storageBucket +
                    `/o/${encodedPath}?alt=media&token=` + randomToken;
            // this.configuration[sIndex]['images'][imageIndex] = fs_url;
            return fs_url;
        },

        stickySidebar(e) {
            
            if( document.getElementById("sticky") == null || document.getElementById('sticky-anchor') == null ) return;
            if (window.innerWidth < 800) {
        
                if( window.scrollY > 50 ){
                    document.querySelector(".nap-config-header-new-wrapper").classList.add('fixed');    
                    document.querySelector(".nap-config-header-new-wrapper").classList.remove('relative');    
                } else {
                    document.querySelector(".nap-config-header-new-wrapper").classList.remove('fixed');    
                    document.querySelector(".nap-config-header-new-wrapper").classList.add('relative');    
                }
            } 

            if (window.innerWidth < 800) return;

            var window_top = window.scrollY;
            var footer_top = document.getElementById("footer").offsetTop;
            var div_top = document.getElementById('sticky-anchor').offsetTop;
            var div_height = document.getElementById("sticky").offsetHeight;

            if (window_top + div_height > (footer_top + 100)) {
                //document.getElementById("sticky").classList.remove('stick');    
            } else if (window_top > (div_top + 30)) {
                document.getElementById("sticky").style.width = this.sidebarWidth + 'px';
                document.getElementById("sticky").classList.add('stick');
            } else {
                document.getElementById("sticky").classList.remove('stick');
            }
            

            
        },
        
        animateDayNightButton(){

            if( !this.isDayNightButtonAnimated ) {
                this.isDayNightButtonAnimated = true;
                this.startAnimateDayNight = true;
                setTimeout(() => {
                    this.startAnimateDayNight = false;
                }, 3000);
            }
        },

        setSlideIndex(index) {
            this.slideCurrentIndex = index;
            this.currentSlide = index;
            // this.slideThumbnailsMargin(index);
            this.activeImageMode = 'day';

            if( typeof this.renderNightImages[index] != 'undefined' && this.renderNightImages[index] != ''  ) {
                this.hasNightMode = true;
                this.animateDayNightButton();

            } else {
                this.hasNightMode = false;
            }

            if (this.slideCurrentIndex > 3) {
                this.slideMarginTop = ((this.slideCurrentIndex - 3) * 72) * (-1);
            } else {
                this.slideMarginTop = 0;
            }
        },
        slideThumbnailsMargin($refs, direction) {
            // console.log(index);
            this.slideCurrentIndex = this.slideCurrentIndex + (direction);
            this.$refs.vueperslides1.goToSlide(this.slideCurrentIndex);
            if (this.slideCurrentIndex > 3) {
                this.slideMarginTop = ((this.slideCurrentIndex - 3) * 72) * (-1);
            } else {
                this.slideMarginTop = 0;
            }

            if (this.sliderThumbnail.length < this.slideCurrentIndex) {
                this.slideCurrentIndex = 0;
            }
        },
        showNextConfigureOnMobile() {

            if (this.mobileConfigureListIndex == (Object.keys(this.configuration).length - 1)) {
                this.mobileConfigureListIndex = 0;
            } else {
                this.mobileConfigureListIndex++;
            }
        },
        addBodyOverflowHidden() {
            document.body.classList.add('overflow-hidden');
        },
        removeBodyOverflowHidden() {
            document.body.classList.remove('overflow-hidden');
        },
        showMapPopup() {
            this.addBodyOverflowHidden();
            this.showMapPop = true;
        },
        hideMapPopup() {
            this.removeBodyOverflowHidden();
            this.showMapPop = false;
        },
        showLayoutPopup() {
            this.addBodyOverflowHidden();
            this.showLayoutPop = true;
        },
        hideLayoutPopup() {
            this.removeBodyOverflowHidden();
            this.showLayoutPop = false;
        },
        showContactPopup() {
            this.addBodyOverflowHidden();
            this.showContactPop = true;
        },
        hideContactPopup() {
            this.removeBodyOverflowHidden();
            this.showContactPop = false;
        },

        showConfigurePopup() {
            this.addBodyOverflowHidden();
            this.showConfigPop = true;
        },
        hideConfigurePopup() {
            this.removeBodyOverflowHidden();
            this.showConfigPop = false;
        },

        toggleFindHow() {

            this.displayFindHow = !this.displayFindHow;

            if (this.displayFindHow == true) {
                this.addBodyOverflowHidden();
            } else {
                this.removeBodyOverflowHidden();
            }

        },


        selectionConfigurationOption(configOption) {
            // console.log(configOption);
            if (this.configurationOptionSelected.indexOf(configOption) == -1) {
                this.configurationOptionSelected.push(configOption);
            } else {
                this.configurationOptionSelected.splice(this.configurationOptionSelected.indexOf(configOption), 1);
            }
            // console.log(this.configurationOptionSelected);
            this.updatePrice();
        },
        updateConfigurationSelection(configname) {
            this.configurationSelected = configname;

        },
        isValidHttpUrl(string) {
            let url;
            
            try {
                url = new URL(string);
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        },
        updateSliderImages(configKey) {

            // this.planImages = this.configuration[configKey]['images'];
            // this.sliderThumbnail = this.configuration[configKey]['images'];
            this.configurationSelected = configKey;

            // let price = parseInt(this.configuration[configKey.toString()]['price']);
            // let eurpermq = price / parseInt(this.property['size']);

            // this.property['price'] = price;
            // this.property['eurpermq'] = parseInt(eurpermq);

            // this.ltv = price * 0.20;
            // this.price = price;
            // this.mortgage = (((this.ltv * 2) * 0.025)/this.mort_duration).toFixed(0);
            this.updatePrice();

        },

        updatePrice() {

            let price = parseInt(this.configuration[this.configurationSelected.toString()]['price']);

            this.property['price'] = price;

            this.ltv = (price * 0.20)//(price * 0.20).toLocaleString();
            this.mortgage = (((this.ltv * 2) * 0.025) / this.mort_duration).toFixed(0);
            let optional = 0;

            if (this.configurationOptionSelected.length > 0) {

                for (let configOptionIndex = 0; configOptionIndex < this.configurationOptionSelected.length; configOptionIndex++) {

                    let element = this.configurationOptionSelected[configOptionIndex];
                    // console.log(element, element['price']);

                    price += parseInt(element['price']);
                    optional += parseInt(element['price']);
                }

            }

            this.optionalTotal = optional;

            // console.log(price);
            this.price = price//price.toLocaleString();
            this.grandTotal = price;
            let eurpermq = price / parseInt(this.property['size']);
            this.property['eurpermq'] = parseInt(eurpermq);

        },
        _toggleRestoration() {
            this.includeRestoration = !this.includeRestoration;
            if( this.includeRestoration ) {
                this.grandTotal = this.price + this.property['restorationCost'];
            } else {
                this.grandTotal = this.price;
            }

            let eurpermq = this.grandTotal / parseInt(this.property['size']);
            this.property['eurpermq'] = parseInt(eurpermq);
            
        }
    },
    data() {
        return {
            isDayNightButtonAnimated: false,
            startAnimateDayNight: false,
            showBeforeAfterPopup: false,
            showBottomSheetPricing: false,
            radius: 400, // 400 meters
            ame_types: ["school", "market", "park"],
            nearbyPlaces: { school: [], market: [], park: [] },
            loadingPlaces: true,
            projectStatusData: {
                'Progetto' : {
                    'heading-color': '#000',
                    'text-color': '#000',
                    'border-color': '#DEDEDE',
                    'bg-color': '#F4F4F4',
                    'link-color': '#489B79',
                    'tag-text-color': '#fff',
                    'description': "Gli immobili di questo tipo sono case ancora da ristrutturare, per le quali esiste un progetto pronto ad essere realizzato. Su questi immobili puoi personalizzare la ristrutturazione e farla su misura per te. Contattaci e chiedi un preventivo di ristrutturazione gratuito!",
                    
                },
                'Completata' : {
                    'heading-color': '#fff',
                    'text-color': '#fff',
                    'border-color': '#489B79',
                    'bg-color': '#489B79',
                    'link-color': '#fff',
                    'tag-text-color': '#489B79',
                    'description': "Gli immobili di questo tipo sono case già ristrutturate e subito pronte per essere abitate. Puoi ancora personalizzare le decorazioni, gli arredi e la cucina. Richiedi un progetto di arredo!",
                },
                'In ristrutturazione' : {
                    'heading-color': '#489B79',
                    'text-color': '#000',
                    'border-color': '#AACBBE',
                    'bg-color': '#EBFAF4',
                    'link-color': '#489B79',
                    'tag-text-color': '#fff',
                    'description': "Gli immobili di questo tipo sono case in fase di ristrutturazione completamente personalizzabili nella scelta dei materiali, delle finiture e delle decorazioni. Vuoi visionare l’immobile? Prenota una visita in cantiere!",
                },
            },
            activeImageMode: 'day',
            hasNightMode: false,
            includeRestoration: false,
            project: {},
            property: {
                'projectStatus': ''
            },
            agency: {},
            sliderActiveClass: '',
            stateSliderActiveClass: 'project',
            showStyle: false,
            displayStyleMoreInfo: false,
            displayFindHow: false,
            isMobile: false,
            isBottomExpanded: false,
            isLoading: true,
            popupMap: 'map-desktop-popup',
            id2: 'map-desktop',
            showConfigPop: false,
            showMapPop: false,
            showLayoutPop: false,
            showContactPop: false,
            price: null,
            grandTotal: 0,
            optionalTotal: 0,
            ltv: null,
            mort_duration: 25,
            daysLeftToComplete: 0,
            progress: {
                'completed': 50,
                'deadline': 95
            },
            mobileConfigureListIndex: 0,
            configurationSelected: 0,
            configuration: [],
            configurationOptionSelected: [],
            configurationOptions: [
                {
                    'image': 'climatizzatore.jpg',
                    'title': 'Condizionatore',
                    'price': '1399',
                    'help': 'Climatizzatore Ariston Alys Plus R-32 trial split, Potenza 9000 btu, wifi, 12 velocità'
                },
                {
                    'image': 'antifurto.jpg',
                    'title': 'Antifurto',
                    'price': '249',
                    'help': 'Pannello di controllo, Sensori volumetrici con fotocamera, Cartelli dissuasori, Sirena, Sensori porte e finestre, App e lettore chiavi, Fumogeno zero vision'
                },
                {
                    'image': 'assicurazione.jpg',
                    'title': 'Assicurazione casa',
                    'price': '599',
                    'help': 'Incendio e scoppio, Eventi naturali, Arredi e contenuto, Furto'
                },
                {
                    'image': 'cucina.jpg',
                    'title': 'Cucina',
                    'price': '7899',
                    'help': 'Cucina ArTre modello Up Design in composizione lineare 425x200 con isola frontale. Colorazione bianco opaco con top marmo. Inclusi nel prezzo poker di elettrodomestici (fuochi, forno, frigo e lavastoviglie) Hotpoint Ariston.'
                }
            ],
            sidebarWidth: 0,
            slideCurrentIndex: 0,
            slideMarginTop: 0,
            tmpIndex: 0,
            testing: 'Hello world!',
            actualVirtualTourLink: '',
            actualRenderImages: [],
            actualPlanImages: [],
            renderImages: [],
            renderNightImages: [],
            beforeAfterImages: [],
            virtualTourLink: '',
            planImages: [],
            sliderImages: [],
            sliderThumbnail: [],
            buildingPlanImages: [
                'nap-plan.png',
                'nap-plan.png',
                'nap-plan.png'
            ],
            selectedDesign: "",
            styleMoreInfo: {
                'Stoccolma': [
                    {
                        'title': 'Tinta pareti',
                        'description': 'Colore: Beige, \nRAL: 1014',
                        'image': 'stoccolma1.jpg'
                    },
                    {
                        'title': 'Pavimento',
                        'description': 'Gres porcellanato effetto legno rovere chiaro 20x120cm',
                        'image': 'stoccolma2.jpg'
                    },
                    {
                        'title': 'Rivestimento bagno',
                        'description': 'Gres porcellanato effetto marmo 60x120cm',
                        'image': 'stoccolma3.jpg'
                    },
                    {
                        'title': 'Porte interne',
                        'description': 'Porte interne timburate a, chiusura magnetica. Colore: Beige',
                        'image': 'stoccolma4.jpg'
                    }
                ],
                'Londra': [
                    {
                        'title': 'Tinta pareti',
                        'description': 'Colore: Beige, RAL: 1014',
                        'image': 'londra1.jpg'
                    },
                    {
                        'title': 'Pavimento',
                        'description': 'Gres porcellanato effetto legno rovere chiaro 20x120cm',
                        'image': 'londra1.jpg'
                    },
                    {
                        'title': 'Rivestimento bagno',
                        'description': 'Gres porcellanato effetto marmo 60x120cm',
                        'image': 'parigi3.jpg'
                    },
                    {
                        'title': 'Porte interne',
                        'description': 'Porte interne timburate a, chiusura magnetica. Colore: Beige',
                        'image': 'stoccolma4.jpg'
                    }
                ],
                'Parigi': [
                    {
                        'title': 'Tinta pareti',
                        'description': "Colore: Beige, RAL: 1014",
                        'image': 'parigi1.jpg'
                    },
                    {
                        'title': 'Pavimento',
                        'description': 'Gres porcellanato effetto legno rovere chiaro 20x120cm',
                        'image': 'parigi2.jpg'
                    },
                    {
                        'title': 'Rivestimento bagno',
                        'description': 'Gres porcellanato effetto marmo 60x120cm',
                        'image': 'parigi3.jpg'
                    },
                    {
                        'title': 'Porte interne',
                        'description': 'Porte interne timburate a, chiusura magnetica. Colore: Beige',
                        'image': 'parigi4.jpg'
                    }

                ]
            },
            findHowInfo: {
                'features':
                    [
                        {
                            'title': "Trasformazione \ndegli ambienti",
                            'description': 'I nostri professionisti ricercano la soluzione migliore per ogni casa rimodulando gli spazi affinché siano luminosi, eleganti ed accoglienti.',
                            'image': 'trasformazione-degli-ambienti.jpeg'
                        },
                        {
                            'title': 'Case \nintelligenti',
                            'description': 'Le case Newarc sono dotate di domotica smart per controllare luci e tapparelle con lo smartphone o con gli assistenti vocali.',
                            'image': 'case-intelligenti.jpeg'
                        },
                        {
                            'title': 'Installazioni \ncertificate',
                            'description': 'Gli impianti elettrici, idrici e a gas sono sempre nuovi e certificati. Ci avvaliamo solo di installatori professionali con anni di esperienza.',
                            'image': 'installazioni-certificate.jpeg'
                        },
                        {
                            'title': 'Miglioramento \nclasse energetica',
                            'description': 'I lavori di ristrutturazione eseguiti nelle nostre case permettono di migliorare l’efficienza energetica e di abbattere le emissioni.',
                            'image': 'miglioramento-classe-energetica.jpeg'
                        }
                    ],
                'special': [
                    {
                        'title': '2 anni di garanzia',
                        'description': 'Al termine della ristrutturazione, avrai 2 anni di assicurazione e riparazione gratuiti.',
                    },
                    {
                        'title': 'Assistenza dedicata',
                        'description': 'Il nostro team sarà a tua completa disposizione per ogni esigenza.',
                    },
                    {
                        'title': 'Personalizzazioni',
                        'description': 'Possibilità di modificare i materiali da capitolato e di scegliere diversi tipi di tinta.',
                    },
                    {
                        'title': 'Interior Design',
                        'description': 'Realizzazione di un progetto personalizzato con un interior designer partner.',
                    }
                ]
            },
            amenitiesCount: {
                school: 0,
                market: 0,
                park: 0

            },
            mapPosition: {
                moveToRight: false,
                showPopup: false,
                center: {
                    lat: 45.072883,
                    lng: 7.666794
                },
                property: [],
                // schools: [],
                // supermarket: [
                //     // {
                //     //     lat: 45.072013, lng: 7.664136
                //     // },
                //     // {
                //     //     lat: 45.073012, lng: 7.663528
                //     // }
                // ],
                // park: [
                //     // { lat: 45.073052, lng: 7.668724 },
                //     // { lat: 45.075365, lng: 7.666859 }
                // ]

                // {
                // lat: 45.091379, 
                // lng:7.679094
                // },
                // {
                // lat: 45.094397,
                // lng:7.682490
                // },
                // {
                // lat: 45.093421,
                // lng:7.673938
                // }
            }
        }
    },
    beforeMount() {
        window.scrollTo(0, 0);
    },
    components: {
        Navbar,
        FooterComponent,
        ArrowLeft,
        ArrowRight,
        ExportVariant,
        Pencil,
        Check,
        ChevronDown,
        ChevronUp,
        ChevronLeft,
        Close,
        VueperSlides,
        VueperSlide,
        MapListingPage,
        MobilePopupConfiguration,
        MobilePopupLayout,
        StyleMoreInfo,
        PopupContact,
        MobilePopupMap,
        PopupFindHow,
        NarTooltip,
        ChevronLeft,
        SendOutline,
        PencilOutline,
        BrushVariant,
        HeaderSidebar,
        BeforeAfterPopup
    }
}

</script>